import FancyTextArea from '../../../../components/FancyTextArea';
import { useStoryFormStore } from '../../../../utils/stores';
import { useForm } from 'react-hook-form';
import { FormStoryPart1 } from '../StoryModal';
import { useWizard } from 'react-use-wizard';
import { useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRight } from '@fortawesome/free-solid-svg-icons';
import { useTranslation } from 'react-i18next';

const StoryWizardStep1 = () => {
  const { t } = useTranslation('global');
  const { part1, updatePart1 } = useStoryFormStore(); // Used to store the values of step 1 of the modal

  const {
    register,
    handleSubmit,
    control,
    formState: { errors },
    reset,
  } = useForm<FormStoryPart1>();

  useEffect(() => {
    // Setting the values in the form to what was saved
    reset({
      title: part1?.title,
      setting: part1?.setting,
      epilogue: part1?.epilogue ?? '',
    });
  }, [part1, reset]);

  const { nextStep } = useWizard(); // Contains part 2 of the modal

  // Save the content of part 1 and move to part 2
  const handleNextStep = async (data: FormStoryPart1): Promise<void> => {
    updatePart1(data);
    await nextStep();
  };

  return (
    <form onSubmit={handleSubmit(handleNextStep)} data-testid='storyWizardStep1'>
      <div className='mb-3'>
        <label htmlFor='storyTitle' className='form-label'>
          {t('title')}
        </label>
        <input
          type='text'
          id='storyTitle'
          className={`form-control ${errors.title ? 'is-invalid' : ''}`}
          {...register('title', { required: true })}
        />
        {errors.title && <span className='invalid-feedback'>{t('title.required')}</span>}
      </div>

      <div className='mb-3'>
        {/* Story for step 1 of the substory */}
        <label htmlFor='storySetting' className='form-label'>
          {t('setting')}
        </label>
        <FancyTextArea<FormStoryPart1>
          id='storySetting'
          defaultValue={''}
          name='setting'
          required={true}
          control={control}
          className={`${errors.setting ? 'is-invalid' : ''}`}
        />
        {errors.setting && <span className='invalid-feedback'>{t('setting.required')}</span>}
      </div>
      <div className='mb-3'>
        {/* Conclusion of the story for step 1 of the substory */}
        <label htmlFor='storyEpilogue' className='form-label'>
          {t('epilogue')}
        </label>
        <FancyTextArea<FormStoryPart1>
          id='storyEpilogue'
          defaultValue={''}
          name='epilogue'
          required={false}
          control={control}
          className={`${errors.epilogue ? 'is-invalid' : ''}`}
        />
        {errors.epilogue && <span className='invalid-feedback'>{t('epilogue.required')}</span>}
      </div>

      <button type='submit' className='btn btn-primary mb-3' data-testid='submit'>
        {t('nextStep')} <FontAwesomeIcon icon={faArrowRight} className='ms-1' />
      </button>
    </form>
  );
};

export default StoryWizardStep1;
