import { fetchAPI } from '../../utils/httpRequests';
import { Quest, QuestLog, QuestLogStatus } from '../teacher/quests/Quests';
import { Link, useParams } from 'react-router-dom';
import { useQuery } from 'react-query';
import Loading from '../../components/Loading';
import { useTranslation } from 'react-i18next';
import { useAuth } from '../../utils/stores';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import { useState } from 'react';
import CreateQuestModal from '../teacher/quests/modals/CreateQuestModal';

const StudentQuests = () => {
  const { t } = useTranslation('global');
  const { courseId } = useParams<{ courseId: string }>();
  const { user } = useAuth();
  const [createModalOpen, setCreateModalOpen] = useState(false); // Controls the state of the modal that creates quests

  // Get all quests and their state
  const { data: quests, isLoading: isQuestLoading } = useQuery(
    `quests-by-course-${courseId}`,
    async () => {
      const quests = await fetchAPI<Quest[]>(`/quests/by-course/${courseId}`);
      if (quests.isSuccess) {
        return quests.data;
      } else {
        return null;
      }
    },
  );

  // Get the quest logs of the student (progression in the quests)
  const { data: questLogs, isLoading: isLogsLoading } = useQuery(
    `quests-logs-by-course-${courseId}`,
    async () => {
      const quests = await fetchAPI<QuestLog[]>(`/quests/by-course/${courseId}/logs`);
      if (quests.isSuccess) {
        return quests.data;
      } else {
        return null;
      }
    },
  );

  const getQuestStatus = (questId: number): QuestLogStatus => {
    const questLog = questLogs?.find((log) => log.questId === questId);
    return questLog?.status ?? 'NOT_STARTED';
  };

  const questStatusActionText = (questId: number): string => {
    const status = getQuestStatus(questId);
    if (status === 'COMPLETED' || status === 'COMPLETED_WITH_DIFFERENTIATION') {
      return t('viewQuest');
    } else if (
      status === 'IN_PROGRESS' ||
      status === 'FAILED' ||
      status === 'WAITING_ON_VALIDATION'
    ) {
      return t('resumeQuest');
    }
    return t('startQuest');
  };

  // Set the status of a quest based on the logs
  const QuestStatus = ({ questId }: { questId: number }): JSX.Element => {
    const status = getQuestStatus(questId);
    if (status === 'COMPLETED') {
      return <span className='badge rounded-pill text-bg-success'>{t('statuses.completed')}</span>;
    } else if (
      status === 'IN_PROGRESS' ||
      status === 'FAILED' ||
      status === 'WAITING_ON_VALIDATION'
    ) {
      return <span className='badge rounded-pill text-bg-info'>{t('statuses.inProgress')}</span>;
    }
    return <span className='badge rounded-pill text-bg-danger'>{t('statuses.notStarted')}</span>;
  };

  return (
    <>
      <div className='m-3' data-testid='studentQuests'>
        <div className='d-flex justify-content-between'>
          <h1>{t('quests')}</h1>
          {user?.teacher && (
            <button className='btn btn-primary mb-2 h-fit' onClick={() => setCreateModalOpen(true)}>
              <FontAwesomeIcon icon={faPlus} className='me-1' /> {t('quests.create_button')}
            </button>
          )}
        </div>
        {isQuestLoading || isLogsLoading ? (
          <Loading />
        ) : (
          <div>
            {/* This will display all the quests of a specific class for a student */}
            {quests?.map((quest) => (
              <div className='card my-3' key={quest.id}>
                <div className='card-header d-flex justify-content-between align-items-center'>
                  <h5>{quest.name}</h5>
                  <QuestStatus questId={quest.id} />
                </div>
                <div className='card-body d-flex gap-2'>
                  <Link to={`${quest.id}`}>
                    <button className='btn btn-primary'>{questStatusActionText(quest.id)}</button>
                  </Link>
                  {user?.teacher && (
                    <Link to={`${quest.id}/submissions`}>
                      <button className='btn btn-secondary'>{t('seeSubmissions')}</button>
                    </Link>
                  )}
                  {user?.teacher && (
                    <Link to={`${quest.id}/submissions/${user.id}`}>
                      <button className='btn btn-secondary'>{t('seeTeacherSubmissions')}</button>
                    </Link>
                  )}
                </div>
              </div>
            ))}
            {quests?.length === 0 && (
              <div className='alert alert-warning' role='alert'>
                {t(user?.teacher ? 'noQuests' : 'noQuestsStudents')}
              </div>
            )}
          </div>
        )}
      </div>
      {user?.teacher && (
        <CreateQuestModal
          isOpen={createModalOpen}
          onClose={() => setCreateModalOpen(false)}
          courseIds={courseId ? [Number(courseId)] : null}
        />
      )}
    </>
  );
};

export default StudentQuests;
