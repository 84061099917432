import { useForm } from 'react-hook-form';
import { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { signIn, type SignInBody } from '../../../utils/auth';
import './Login.css';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import RequestResetPasswordModal from '../../../components/modals/RequestResetPasswordModal';

const Login = () => {
  const { t } = useTranslation('global');
  const [signInError, setSignInError] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  const [showResetModal, setShowResetModal] = useState(false);
  const showPasswordReset = location.state?.passwordReset || false;
  const [showPasswordResetTemporarily, setShowPasswordResetTemporarily] =
    useState(showPasswordReset);

  const handleResetPassword = () => {
    setShowResetModal(true);
  };

  const closeModal = () => {
    setShowResetModal(false);
  };

  const {
    register,
    formState: { errors },
    handleSubmit,
  } = useForm<SignInBody>();

  // Attempts to sign in user
  const onSubmit = async (data: SignInBody) => {
    setShowPasswordResetTemporarily(false);
    setSignInError(false);
    const loggedIn = await signIn(data);
    if (loggedIn) {
      navigate('/welcome');
    } else {
      setSignInError(true);
    }
  };

  return (
    <div className='body-signin' data-testid='login'>
      {/* Form for user to sign in with email or username */}
      <form className='form-signin' onSubmit={handleSubmit(onSubmit)}>
        {showPasswordResetTemporarily && (
          <div className='alert alert-success' role='alert'>
            {t('signInWithNewPassword')}
          </div>
        )}
        {signInError && (
          <div className='alert alert-danger d-flex justify-content-between' role='alert'>
            {t('wrongCredentialsLogin')}
          </div>
        )}
        <h1 className='h3 mb-3 font-weight-normal text-center'>{t('signinMSG')}</h1>
        <div className='mb-3'>
          <label htmlFor='email'>{t('userId')}</label>
          <input
            type='text'
            data-testid='emailInput'
            {...register('emailOrUsername', { required: t('userIdRequiredError') })}
            className={`form-control ${errors.emailOrUsername ? 'is-invalid' : ''}`}
          />
          {errors.emailOrUsername && (
            <div className='invalid-feedback'>{errors.emailOrUsername.message}</div>
          )}
        </div>
        <div className='mb-3'>
          <label htmlFor='password'>{t('password')}</label>
          <input
            type='password'
            id='password'
            {...register('password', { required: t('passwordRequiredError') })}
            className={`form-control ${errors.password ? 'is-invalid' : ''}`}
          />
          {errors.password && <div className='invalid-feedback'>{errors.password.message}</div>}
        </div>
        <button className='btn btn-lg btn-primary btn-block' data-testid='submit'>
          {t('login')}
        </button>
        {/* Link to sign up page */}
        <p className='text-center'>
          {t('noAccount')} <Link to={'/auth/sign-up'}>{t('signup')}</Link>
        </p>
        {/* On Click opens RequestResetPasswordModal */}
        <p className='text-center'>
          {t('didYouForgetPassword')}
          <Link to='#' onClick={handleResetPassword} data-testid='handleReset'>
            {t('resetPassword')}
          </Link>
        </p>
      </form>

      {/*Modal to request link to reset password */}
      <RequestResetPasswordModal isOpen={showResetModal} onClose={closeModal} />
    </div>
  );
};

export default Login;
