import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPencil, faPlus, faTrash } from '@fortawesome/free-solid-svg-icons';
import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';
import { fetchAPI } from '../../../utils/httpRequests';
import { Concept } from '../quests/Quests';
import { useState } from 'react';
import ConceptModal from './ConceptModal';
import Loading from '../../../components/Loading';

const ConceptsPage = () => {
  const { t } = useTranslation('global');
  const [createModalOpen, setCreateModalOpen] = useState(false); // Controls the state of the modal that creates concepts
  const [editConcept, setEditConcept] = useState<Concept | null>(null); // Contains the concept that will be edited

  // Get all concepts
  const fetchConcepts = async (): Promise<Concept[] | null> => {
    const quest = await fetchAPI<Concept[]>(`/concepts`);
    if (quest.isSuccess) {
      return quest.data;
    } else {
      return null;
    }
  };

  const updateConcept = (concept: Concept) => {
    setEditConcept(concept);
    setCreateModalOpen(true);
  };

  // Close the opened modal
  const closeModal = async (): Promise<void> => {
    await refetch();
    setCreateModalOpen(false);
    setEditConcept(null);
  };

  const deleteConcept = async (concept: Concept) => {
    const response = await fetchAPI(`/concepts/${concept.id}`, {
      method: 'DELETE',
    });

    if (response.isSuccess) {
      await refetch();
    }
  };

  const { data: concepts, isLoading, refetch } = useQuery('concepts', fetchConcepts);

  return (
    <>
      <div className='mx-md-5 my-3' data-testid='conceptsPage'>
        <div className='d-flex align-items-center justify-content-between'>
          <div>
            <h1>{t('concepts.page')}</h1>
          </div>
          <div>
            <button onClick={() => setCreateModalOpen(true)} className='btn btn-primary'>
              <FontAwesomeIcon icon={faPlus} className='me-1' /> {t('concept.create')}
            </button>
          </div>
        </div>
        <div>
          {/* This displays all the concepts a teacher as created */}
          {isLoading ? (
            <Loading />
          ) : (
            concepts?.map((concept) => (
              <div className='card my-2' key={concept.id}>
                <div className='card-body'>
                  <h5 className='card-title'>{concept.title}</h5>
                  <div className='d-flex gap-2'>
                    <button className='btn btn-primary' onClick={() => updateConcept(concept)}>
                      <FontAwesomeIcon icon={faPencil} className='me-2' /> {t('edit')}
                    </button>
                    <button className='btn btn-danger' onClick={() => deleteConcept(concept)}>
                      <FontAwesomeIcon icon={faTrash} className='me-2' /> {t('delete')}
                    </button>
                  </div>
                </div>
              </div>
            ))
          )}
        </div>
      </div>
      <ConceptModal isOpen={createModalOpen} onClose={closeModal} editConcept={editConcept} />
    </>
  );
};

export default ConceptsPage;
