import { Link, useParams } from 'react-router-dom';
import { fetchCourse, fetchQuest } from '../../../utils/httpRequests';
import { useQuery } from 'react-query';
import Loading from '../../../components/Loading';
import { useTranslation } from 'react-i18next';
import { useState } from 'react';

const QuestSubmissions = () => {
  const { t } = useTranslation('global');
  const { courseId, questId } = useParams<{ courseId: string; questId: string }>();
  const { data: course, isLoading } = useQuery(['course', courseId], () => fetchCourse(courseId));
  const { data: quest, isLoading: isQuestLoading } = useQuery(['quest', questId], () =>
    fetchQuest(questId),
  );
  const participants = course?.participants || [];
  const [searchTerm, setSearchTerm] = useState('');

  // Handle the searchbar
  const filteredParticipants = participants
    .filter((participant) => participant.role === 'STUDENT')
    .filter((participant) => {
      const fullName = participant.user.firstName + ' ' + participant.user.lastName;

      return fullName.toLowerCase().includes(searchTerm.toLowerCase());
    });

  // Handle search input change and clear selected students
  const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(e.target.value); // Update the search term
  };

  // Handle highlighting the text matching the search term. Color can be changed in css file
  const highlightSearchTerm = (text: string, searchTerm: string) => {
    if (!searchTerm) return text; // If no search term, return the original text

    const regex = new RegExp(`(${searchTerm})`, 'gi');
    const parts = text.split(regex);

    return parts.map((part, index) =>
      part.toLowerCase() === searchTerm.toLowerCase() ? (
        <span key={index} className='highlight'>
          {part}
        </span>
      ) : (
        part
      ),
    );
  };

  return (
    <div className='m-2'>
      {isLoading || isQuestLoading || !(course && course.participants) ? (
        <Loading />
      ) : (
        <div data-testid='questSubmissions'>
          <h1>
            {t('questSubmissions')} - {quest?.name}
          </h1>
          {course.participants.some((p) => p.role === 'STUDENT') ? (
            <div>
              <h2 className='fs-5'>{t('chooseYourStudent')}</h2>
              <div className='my-3'>
                <input
                  type='search'
                  className='form-control w-25 bg-light border-dark text-dark shadow-sm'
                  placeholder={t('search')}
                  value={searchTerm}
                  onChange={handleSearchChange}
                />
              </div>
              <div className='list-group'>
                {/* Display all the students within the class for a specific quest to access each students submissions */}
                {filteredParticipants
                  .sort((a, b) =>
                    a.user.firstName.toLowerCase().localeCompare(b.user.firstName.toLowerCase()),
                  )
                  .map((participant) => (
                    <Link
                      key={participant.id}
                      to={`${participant.id}`}
                      className='list-group-item list-group-item-action'
                    >
                      {highlightSearchTerm(
                        participant.user.firstName + ' ' + participant.user.lastName,
                        searchTerm,
                      )}
                    </Link>
                  ))}
              </div>
            </div>
          ) : (
            <h6>{t('noStudentsMessage')}</h6>
          )}
        </div>
      )}
    </div>
  );
};

export default QuestSubmissions;
