// file for helper functions related to all avatar status like level, xp, gold...

// calculates an avatar's level
export const getAvatarLevel = (responseXp: number, xpPerLevel: number): number => {
  return Math.floor(responseXp / xpPerLevel) + 1;
};

// get's the next needed amount of xp to achieve the next level
export const getNextLevelXP = (avatarXp: number, xpPerLevel: number): number => {
  let nextLevelXP = Math.ceil(avatarXp / xpPerLevel);
  if (nextLevelXP == 0) {
    nextLevelXP = 1;
  }
  if (nextLevelXP * xpPerLevel == avatarXp) {
    nextLevelXP = nextLevelXP + 1;
  }
  return nextLevelXP * xpPerLevel;
};
