import { useTranslation } from 'react-i18next';
import './Loading.css';

const Loading = ({ insideModal }: { insideModal?: string }) => {
  const { t } = useTranslation('global');

  let className;
  let style;

  // Define the style of the loading overlay depending on the context
  switch (insideModal) {
    case 'requestPassword':
      className = 'loading-overlay';
      style = { marginBottom: '10%' };
      break;
    case 'coursePage':
      className = 'loading-overlay-2';
      style = {};
      break;
    default:
      className = 'w-100 min-vh-100 d-flex justify-content-center';
      style = { marginTop: '10%' };
      break;
  }

  return (
    <div className={className} style={style} data-testid='loading'>
      <div className='spinner-grow' style={{ width: '3em', height: '3em' }} role='status'>
        <span className='visually-hidden'>{t('loading')}</span>
      </div>
    </div>
  );
};

export default Loading;
