import { useForm } from 'react-hook-form';
import { signUp } from '../../../utils/auth';
import type { SignUpBody } from '../../../utils/auth';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import ReCAPTCHA from 'react-google-recaptcha';
import { Link } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import { useEffect, useRef, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleInfo } from '@fortawesome/free-solid-svg-icons';
import { Tooltip } from 'react-tooltip';
import './SignUpForm.css';

const SignUpForm = () => {
  const { t } = useTranslation('global');
  const { role } = useParams<{ role: string }>();
  const navigate = useNavigate();
  const [showErrorAlert, setShowErrorAlert] = useState(false);
  const [errorText, setErrorText] = useState('');
  const [captchaValue, setCaptchaValue] = useState('');
  const [captchaError, setCaptchaError] = useState(false);
  const recaptchaRef = useRef<ReCAPTCHA>(null);

  function onChangeCaptcha(value: string | null) {
    setCaptchaValue(value === null ? '' : value);
    if (value !== '' && value !== null) {
      setCaptchaError(false);
    }
  }

  function isCaptchaSet() {
    if (captchaValue === '') {
      setCaptchaError(true);
    }
  }

  const {
    register,
    handleSubmit,
    formState: { errors },
    watch,
  } = useForm<SignUpBody>();

  const validatePassword = (value: string, t: (key: string) => string) => {
    const minLength = 8;
    const hasLowerCase = /[a-z]/.test(value);
    const hasUpperCase = /[A-Z]/.test(value);
    const hasDigit = /\d/.test(value);
    const hasSpecialChar = /[!@#$%^&*()_+{}[\]:;<>,.?~\\/-]/.test(value);

    const errors = [];

    if (value.length < minLength) errors.push(t('passwordMinLengthError'));
    if (!hasLowerCase) errors.push(t('passwordLowerCaseError'));
    if (!hasUpperCase) errors.push(t('passwordUpperCaseError'));
    if (!hasDigit) errors.push(t('passwordDigitError'));
    if (!hasSpecialChar) errors.push(t('passwordSpecialCharError'));

    return errors.length > 0 ? errors.join(' ') : true; // Return errors array or true if valid
  };

  // Checks if Password and Confirm Password match
  const validateConfirmPassword = (value: string) => {
    const passwordValue = watch('password');
    return value === passwordValue || t('confirmPasswordError');
  };

  // Submits sign up request
  const onSubmit = async (data: SignUpBody) => {
    if (captchaError) {
      return;
    } else {
      data.captchaToken = captchaValue;
    }

    if (role === 'teacher') {
      data.role = 'TEACHER';
    } else {
      data.role = 'STUDENT';
    }
    const signedUp = await signUp(data);

    // Checks for success or error message
    switch (signedUp) {
      case 'success':
        navigate('/welcome');
        break;
      case 'Access code for class invalid.':
        setErrorText(t('accessCodeError'));
        break;
      case 'User failed the captcha verification':
        setErrorText(t('captchaErrorBE'));
        break;
      case 'Email and Username error':
        setErrorText(t('emailUsernameErrorBE'));
        break;
      case 'Email error':
        setErrorText(t('emailErrorBE'));
        break;
      case 'Username error':
        setErrorText(t('usernameErrorBE'));
        break;
      default:
        setErrorText(t('unexpectedError'));
        break;
    }
  };

  // Shows error alert when error message is set
  useEffect(() => {
    if (errorText !== '') {
      setShowErrorAlert(true);
      recaptchaRef.current?.reset();
    } else {
      setShowErrorAlert(false);
    }
  }, [errorText]);

  return (
    <div className='container my-5' data-testid='signUpForm'>
      {/* Alert for sign up error */}
      {showErrorAlert && (
        <div className='alert alert-danger' role='alert'>
          {errorText}
        </div>
      )}
      <div className='p-4 bg-body-tertiary rounded-3'>
        <h1 className='text-body-emphasis'>{t('information')}</h1>
        {/* Form for sign up */}
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className='row'>
            <div className='col-md-6 mb-3'>
              <label htmlFor='firstName'>{t('firstName')}</label>
              <input
                type='text'
                id='firstName'
                {...register('firstName', { required: t('firstNameRequiredError') })}
                className={`form-control ${errors.firstName ? 'is-invalid' : ''}`}
              />
              {errors.firstName && (
                <div className='invalid-feedback'>{errors.firstName.message}</div>
              )}
            </div>
            <div className='col-md-6 mb-3'>
              <label htmlFor='lastName'>{t('lastName')}</label>
              <input
                type='text'
                id='lastName'
                {...register('lastName', { required: t('lastNameRequiredError') })}
                className={`form-control ${errors.lastName ? 'is-invalid' : ''}`}
              />
              {errors.lastName && <div className='invalid-feedback'>{errors.lastName.message}</div>}
            </div>
          </div>
          <div className='row'>
            {role == 'teacher' ? (
              <>
                {/* If TEACHER email required */}
                <div className='col-md-6 mb-3'>
                  <label htmlFor='email'>{t('email')}</label>
                  <input
                    type='text'
                    id='email'
                    {...register('email', {
                      required: t('emailRequiredError'),
                      pattern: /^\S+@\S+$/i,
                    })}
                    className={`form-control ${errors.email ? 'is-invalid' : ''}`}
                  />
                  {errors.email && (
                    <div className='invalid-feedback'>
                      {errors.email.type === 'required'
                        ? t('emailRequiredError')
                        : t('emailFormatError')}
                    </div>
                  )}
                </div>
                <div className='col-md-6 mb-3'>
                  <label htmlFor='username'>{t('username')}</label>
                  <input
                    type='text'
                    id='username'
                    {...register('username', { required: t('usernameRequiredError') })}
                    className={`form-control ${errors.username ? 'is-invalid' : ''}`}
                  />
                  {errors.username && (
                    <div className='invalid-feedback'>{errors.username.message}</div>
                  )}
                </div>
              </>
            ) : (
              <>
                {/* If STUDENT email not required if accessCode given */}
                <div className='col-md-6 mb-3'>
                  <label htmlFor='email'>{t('email')}</label>
                  <input
                    type='text'
                    id='email'
                    {...register('email', {
                      pattern: /^\S+@\S+$/i,
                      required: watch('accessCode') ? undefined : t('emailOrCourseRequiredError'),
                    })}
                    className={`form-control ${errors.email ? 'is-invalid' : ''}`}
                  />
                  {errors.email && (
                    <div className='invalid-feedback'>
                      {errors.email.type === 'required'
                        ? t('emailOrCourseRequiredError')
                        : t('emailFormatError')}
                    </div>
                  )}
                </div>
                <div className='col-md-3 mb-3'>
                  <label htmlFor='username'>{t('username')}</label>
                  <input
                    type='text'
                    id='username'
                    {...register('username', { required: t('usernameRequiredError') })}
                    className={`form-control ${errors.username ? 'is-invalid' : ''}`}
                  />
                  {errors.username && (
                    <div className='invalid-feedback'>{errors.username.message}</div>
                  )}
                </div>
                <div className='col-md-3 mb-3'>
                  <label htmlFor='courseCode'>
                    {t('createAccount.accessCode')}{' '}
                    <a data-tooltip-id='my-tooltip' data-tooltip-content={t('accessCodeInfo')}>
                      <FontAwesomeIcon icon={faCircleInfo} />
                    </a>
                    <Tooltip id='my-tooltip' />
                  </label>
                  <input
                    type='text'
                    id='courseCode'
                    {...register('accessCode')}
                    className={`form-control`}
                  />
                </div>
              </>
            )}
          </div>
          <div className='row'>
            <div className='col-md-6 mb-3'>
              <label htmlFor='password'>
                {t('password')}{' '}
                <a data-tooltip-id='my-tooltip' data-tooltip-content={t('passwordFormatError')}>
                  <FontAwesomeIcon icon={faCircleInfo} />
                </a>
                <Tooltip id='my-tooltip' />
              </label>
              <input
                type='password'
                id='password'
                {...register('password', {
                  required: t('passwordRequiredError'),
                  validate: (value: string) => {
                    const validationResult = validatePassword(value, t);
                    return validationResult === true || validationResult;
                  },
                })}
                className={`form-control ${errors.password ? 'is-invalid' : ''}`}
              />
              {errors.password && (
                <div className='invalid-feedback'>
                  {errors.password.type === 'required'
                    ? t('passwordRequiredError')
                    : errors.password.message}
                </div>
              )}
            </div>
            <div className='col-md-6 mb-3'>
              <label htmlFor='confirmPassword'>{t('confirmPassword')}</label>
              <input
                type='password'
                id='confirmPassword'
                {...register('confirmPassword', {
                  required: t('confirmPassword'),
                  validate: (value: string) => validateConfirmPassword(value),
                })}
                className={`form-control ${errors.confirmPassword ? 'is-invalid' : ''}`}
              />
              {errors.confirmPassword && (
                <div className='invalid-feedback'>
                  {errors.confirmPassword.type === 'required'
                    ? t('confirmPasswordRequiredError')
                    : t('confirmPasswordMatchError')}
                </div>
              )}
            </div>
          </div>
          <div className='row'>
            <div className='col-md-6'>
              <ReCAPTCHA
                ref={recaptchaRef}
                sitekey={process.env.REACT_APP_RECAPTCHA_SITE_KEY as string}
                onChange={onChangeCaptcha}
                data-testid='recaptcha'
              />
              {captchaError && (
                <div className='text-danger'>
                  <small>{t('captchaTokenRequiredError')}</small>
                </div>
              )}
            </div>
            <div className='col-md-6 mt-auto text-end'>
              <Link
                className='d-inline-flex align-items-center btn btn-outline-secondary btn-lg px-4 rounded-pill me-3'
                type='submit'
                to='/auth/sign-up'
              >
                {t('previous')}
              </Link>
              <button
                className='d-inline-flex align-items-center btn btn-primary btn-lg px-4 rounded-pill'
                type='submit'
                data-testid='submit'
                onClick={isCaptchaSet}
              >
                {t('createAccount')}
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};

export default SignUpForm;
