import { useTranslation } from 'react-i18next';

import { useParams } from 'react-router-dom';
import {
  fetchAPI,
  fetchCourse,
  fetchQuestSubmissions,
  QuestStepQuestionSubmissionResponse,
} from '../../../utils/httpRequests';
import { useQuery } from 'react-query';
import Loading from '../../../components/Loading';
import Status from '../../../components/Status';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck, faCircleExclamation, faTimes } from '@fortawesome/free-solid-svg-icons';
import { useEffect, useState } from 'react';
import ImageAndPdfModal from '../../../components/modals/ImageAndPdfModal';
import BasicModal from '../../../components/modals/BasicModal';
import RenderUnsafeHTML from '../../../components/RenderUnsafeHTML';

const QuestSubmission = () => {
  const { t } = useTranslation('global');
  const { courseId, questId, participantId } = useParams<{
    courseId: string;
    questId: string;
    participantId: string;
  }>();
  const [participantName, setParticipantName] = useState<string>('');
  const {
    data: submissions,
    isLoading,
    refetch,
  } = useQuery(['submissions', courseId, questId, participantId], () =>
    fetchQuestSubmissions(questId, participantId),
  );

  const { data: course, isLoading: isCourseLoading } = useQuery(['course', courseId], () =>
    fetchCourse(courseId),
  );

  useEffect(() => {
    const participant = course?.participants.find((p) => p.id === Number(participantId))?.user;
    setParticipantName(`${participant?.firstName} ${participant?.lastName}`);
  }, [course]);

  const [file, setFile] = useState<string | null>(null);
  const [modalOpen, setModalOpen] = useState<boolean>(false);
  const [solutionsModalOpen, setSolutionsModalOpen] = useState<boolean>(false);
  const [solutions, setSolutions] = useState<string | null>(null);

  const openModal = (url: string) => {
    setFile(url);
    setModalOpen(true);
  };

  const closeModal = () => {
    setFile(null);
    setModalOpen(false);
  };

  const closeSolutionsModal = () => {
    setSolutionsModalOpen(false);
    setSolutions(null);
  };

  const seeSolutions = (solutions: string) => {
    setSolutions(solutions);
    setSolutionsModalOpen(true);
  };

  const FilesComponent = ({ files }: { files: string[] }) => {
    return (
      <div className='card mt-1'>
        <div className='card-body'>
          <h5 className='card-title'>{t('files')}</h5>
          <div className='d-flex gap-2'>
            {files.map((f, i) => (
              <button
                onClick={() => openModal(f)}
                className='btn btn-sm btn-outline-primary'
                key={i}
                data-testid={`file-${i}-${f}`}
              >
                {t('file')} {i}
              </button>
            ))}
          </div>
        </div>
      </div>
    );
  };

  const validateAnswer = async (
    questStepId: number,
    questionId: number | undefined,
    rightAnswer: boolean,
  ) => {
    if (!questionId) return;
    const val = confirm(
      rightAnswer
        ? t('areYouSureYouWantToApproveThisAnswer')
        : t('areYouSureYouWantToRejectThisAnswer'),
    );

    if (val) {
      const res = await fetchAPI(`/quests/steps/${questStepId}/answers/validate`, {
        method: 'PUT',
        body: {
          participantId: Number(participantId),
          questionId: questionId,
          correct: rightAnswer,
        },
      });

      if (res.isSuccess) {
        await refetch();
      }
    }
  };

  // This will display a response to a question from a student in read only mode
  const QuestionFormReadOnly = ({
    s,
    questStepId,
    differentiation,
  }: {
    s: QuestStepQuestionSubmissionResponse;
    questStepId: number;
    differentiation: boolean;
  }) => {
    const q = s.conceptQuestion;
    return (
      <div className='card my-2' key={s.questStepConceptQuestionSubmissionId}>
        <div className='card-body'>
          <div className='d-flex justify-content-between mb-2'>
            <div>
              {differentiation && (
                <span className='badge rounded-pill text-bg-warning'>
                  <FontAwesomeIcon icon={faCircleExclamation} className='me-1' />
                  {t('differenciation')}
                </span>
              )}
            </div>
            {q.solutions ? (
              <button
                onClick={() => seeSolutions(q.solutions)}
                className='btn btn-outline-primary btn-sm'
                type='button'
                data-testid={`seeSolutions-${s.questStepConceptQuestionSubmissionId}`}
              >
                {t('seeSolutions')}
              </button>
            ) : (
              <div></div>
            )}
          </div>
          {/* Display a response for a multiple choice or a multiple select question */}
          <RenderUnsafeHTML html={q.questionText} />
          {q.questionValue.questionType === 'MULTIPLE_CHOICE' ||
          q.questionValue.questionType === 'MULTIPLE_SELECT' ? (
            <div>
              {q.questionValue.choices.map((c, i) => {
                return (
                  <div className='form-check' key={i}>
                    <input
                      disabled={true}
                      type={
                        q.questionValue.questionType === 'MULTIPLE_CHOICE' ? 'radio' : 'checkbox'
                      }
                      checked={s.questionAnswer ? s.questionAnswer?.answer?.includes(c) : undefined}
                      className='form-check-input'
                      value={c}
                    />
                    <label className='form-check-label'>
                      <RenderUnsafeHTML html={c} />
                    </label>
                  </div>
                );
              })}
            </div>
          ) : (
            <div>
              {/* Display the response for a short answer */}
              {q.questionValue.questionType === 'SHORT_ANSWER' ? (
                <input
                  type='text'
                  value={s.questionAnswer?.answer?.length ? s.questionAnswer.answer[0] : ''}
                  readOnly={true}
                  className='form-control'
                />
              ) : (
                <textarea
                  value={s.questionAnswer?.answer?.length > 0 ? s.questionAnswer.answer[0] : ''}
                  readOnly={true}
                  className='form-control'
                />
              )}
            </div>
          )}
          {s.hasSubmittedAnswer ? (
            <div>
              {s.autoGraded || s.success !== null ? (
                <div>
                  {/* Will display the submissions with a different alert based on if it's a good response or bad for autograded questions */}
                  <div className={`mt-2 alert alert-${s.success ? 'success' : 'danger'}`}>
                    <FontAwesomeIcon
                      icon={s.success ? faCheck : faTimes}
                      size='lg'
                      className='me-1'
                    />
                    {s.success ? t('correctAnswer') : t('incorrectAnswer')}
                    {!s.autoGraded && <span className='mx-1 small'>({t('gradedByTeacher')})</span>}
                    {!s.success && q.questionValue.questionType !== 'LONG_ANSWER' && (
                      <div>
                        {t('theCorrectAnswerIs', {
                          count: q.questionType === 'MULTIPLE_SELECT' ? 2 : 1,
                        })}{' '}
                        {q.questionValue.questionType === 'SHORT_ANSWER' ||
                          (q.questionValue.questionType === 'MULTIPLE_CHOICE' &&
                            q.questionValue.correctAnswer)}
                        {q.questionValue.questionType === 'MULTIPLE_SELECT' &&
                          q.questionValue.correctAnswers.join(', ')}
                      </div>
                    )}
                  </div>
                </div>
              ) : (
                <div className='alert alert-info mt-2'>
                  {/* This allows the prof to grade a long format question with a yes or no */}
                  <p className='fw-bold mb-1'>{t('notYetGraded')}</p>
                  <div className='d-flex gap-2'>
                    <button
                      type='button'
                      onClick={() => validateAnswer(questStepId, q.id, true)}
                      className='btn btn-success btn-sm'
                      data-testid={`validateAnswerTrue-${s.questStepConceptQuestionSubmissionId}`}
                    >
                      <FontAwesomeIcon icon={faCheck} size='lg' />
                    </button>
                    <button
                      type='button'
                      onClick={() => validateAnswer(questStepId, q.id, false)}
                      className='btn btn-danger btn-sm'
                      data-testid={`validateAnswerFalse-${s.questStepConceptQuestionSubmissionId}`}
                    >
                      <FontAwesomeIcon icon={faTimes} size='lg' />
                    </button>
                  </div>
                </div>
              )}
              {s.questionAnswer.files.length > 0 && (
                <FilesComponent files={s.questionAnswer.files} />
              )}
            </div>
          ) : (
            <div className='alert alert-warning mt-2'>{t('noAnswerSubmitted')}</div>
          )}
        </div>
      </div>
    );
  };

  // This allows a prof to view a specific students submissions for a specific quest
  return (
    <div className='m-2'>
      {isLoading || isCourseLoading ? (
        <Loading />
      ) : (
        <div data-testid='questSubmission'>
          <h1>
            {t('questSubmissionsFor', {
              name: participantName,
            })}
          </h1>
          <Status status={submissions?.status} />
          <div className='mt-2'>
            {submissions?.questStepSubmissionResponses
              .sort((a, b) => a.questStep.stepOrder - b.questStep.stepOrder)
              .map((qs) => (
                <div key={qs.questStep.id} className='mb-5'>
                  <h2 className='fs-3'>{qs.questStep.concept.title}</h2>
                  {qs.files.length > 0 && <FilesComponent files={qs.files} />}
                  {/* Will display each question response from non differentiation to differantiation section */}
                  {qs.questStepQuestionSubmissionResponses
                    .sort((a) => (a.conceptQuestion.differentiation ? 1 : -1))
                    .map((s) => (
                      <QuestionFormReadOnly
                        differentiation={s.conceptQuestion.differentiation}
                        questStepId={qs.questStep.id}
                        s={s}
                        key={s.questStepConceptQuestionSubmissionId}
                      />
                    ))}
                </div>
              ))}
          </div>
          <ImageAndPdfModal file={file} isOpen={modalOpen} closeModal={closeModal} />
          <BasicModal
            isOpen={solutionsModalOpen}
            closeModal={closeSolutionsModal}
            title={t('solutions')}
            html={solutions}
          />
        </div>
      )}
    </div>
  );
};

export default QuestSubmission;
