import { useTranslation } from 'react-i18next';
import { useParams, useNavigate } from 'react-router-dom';
import {
  fetchAPI,
  fetchCourse,
  getParticipantWithCourseId,
  Participant,
  UpdateAvatar,
  updateAvatar,
} from '../../utils/httpRequests';
import { useQuery } from 'react-query';
import { useAuth } from '../../utils/stores';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faGear } from '@fortawesome/free-solid-svg-icons';
import { useEffect, useState } from 'react';
import './CoursePage.css';
import Loading from '../../components/Loading';
import { Tooltip } from 'react-tooltip';
import GiveRewardsModal from '../../components/modals/GiveRewardsModal';
import { getAvatarLevel, getNextLevelXP } from '../../utils/avatar';

interface NewPasswordBody {
  username: string;
  password: string;
}

interface PasswordResetResponse {
  message: string;
}

interface Avatar {
  name: string;
  image: string;
  description: string;
}

const CoursePage = () => {
  const { t } = useTranslation('global');
  const { user, signedIn } = useAuth();
  const { courseId } = useParams();
  const [loading, setLoading] = useState(false);
  const [deleteLoading, setDeleteLoading] = useState(false);
  const [resetLoading, setResetLoading] = useState(false);
  const [avatarReset, setAvatarReset] = useState('false');
  const [selectedParticipant, setSelectedParticipant] = useState<Participant | null>(null);
  const [infoModalOpen, setInfoModalOpen] = useState(false);
  const [infoModalIsClosing, setInfoModalIsClosing] = useState(false);
  const [selectedTemporaryPassword, setSelectedTemporaryPassword] = useState('********');
  const [passwordSet, setPasswordSet] = useState(false);
  const [error, setError] = useState(false);
  const [passwordCopied, setPasswordCopied] = useState(t('copyPassword'));
  const [initialRender, setInitialRender] = useState(true);
  const [selectedStudents, setSelectedStudents] = useState<number[]>([]);
  const [giveRewardsModalOpen, setGiveRewardsModalOpen] = useState(false);
  const [pointsType, setPointsType] = useState<'xp' | 'gold' | 'life'>('xp'); // Starts as XP, but gets changed by whatever button is clicked
  const [avatarLevel, setAvatarLevel] = useState(0);
  const [studentAvatar, setStudentAvatar] = useState<Participant | null>(null);
  const [studentXP, setStudentXP] = useState('');
  const [studentGold, setStudentGold] = useState('');
  const [studentHearts, setStudentHearts] = useState('');
  const [xpPercentage, setXpPercentage] = useState('0%');
  const [goldPercentage, setGoldPercentage] = useState('0%');
  const [heartsPercentage, setHeartsPercentage] = useState('0%');
  const [avatarDescription, setAvatarDescription] = useState('');
  const [loadingAvatar, setLoadingAvatar] = useState(true);
  const [avatarName, setUpAvatarName] = useState('');
  const navigate = useNavigate();

  const handleGearClick = (participant: Participant) => {
    setSelectedParticipant(participant);
    setInfoModalIsClosing(false);
    setInfoModalOpen(true);
  };

  useEffect(() => {
    if (!initialRender && selectedTemporaryPassword !== '********') {
      const body = {
        username: selectedParticipant?.user.username || '',
        password: selectedTemporaryPassword,
      };
      const fetchData = async (body: NewPasswordBody): Promise<void> => {
        const response = await fetchAPI<PasswordResetResponse>('/auth/set-new-password', {
          method: 'POST',
          body,
        });
        if (response.isSuccess) {
          setError(false);
        } else {
          setError(true);
        }
        setPasswordSet(true);
        setLoading(false);
      };
      fetchData(body);
    }
  }, [selectedTemporaryPassword]);

  const setUpAvatarXP = async (xp: number) => {
    if (!course) {
      return;
    }
    const nextXp = getNextLevelXP(xp, course.xpPerLevel);
    setStudentXP(xp + '/' + nextXp);
    setXpPercentage((xp / nextXp) * 100 + '%');
  };

  const setUpAvatarGold = async (gold: number) => {
    let maxGold = 0;
    if (gold < 10) {
      maxGold = 10;
    } else if (gold < 100) {
      maxGold = 100;
    } else if (gold < 1000) {
      maxGold = 1000;
    } else if (gold < 10000) {
      maxGold = 10000;
    } else if (gold < 25000) {
      maxGold = 25000;
    } else if (gold < 50000) {
      maxGold = 10000;
    } else {
      maxGold = 100000; // Default for anything greater than 10,000
      if (gold > maxGold) {
        maxGold = gold;
      }
    }
    setStudentGold(gold + '/' + maxGold);
    setGoldPercentage((gold / maxGold) * 100 + '%');
  };

  const setUpAvatarHearts = async (hearts: number) => {
    let maxHearts = 0;
    if (hearts < 10) {
      maxHearts = 10;
    } else if (hearts < 25) {
      maxHearts = 25;
    } else if (hearts < 50) {
      maxHearts = 50;
    } else if (hearts < 75) {
      maxHearts = 75;
    } else if (hearts < 100) {
      maxHearts = 100;
    } else if (hearts < 150) {
      maxHearts = 150;
    } else {
      maxHearts = 200; // Default for anything greater than 10,000
      if (hearts > maxHearts) {
        maxHearts = hearts;
      }
    }
    setStudentHearts(hearts + '/' + maxHearts);
    setHeartsPercentage((hearts / maxHearts) * 100 + '%');
  };

  const {
    data: course,
    isLoading,
    refetch,
  } = useQuery(['course', courseId], () => fetchCourse(courseId));

  useEffect(() => {
    if (course) {
      const fetchParticipant = async () => {
        const response = await getParticipantWithCourseId(courseId);
        if (!response.avatar || response.avatar === '') {
          navigate('/courses/' + courseId + '/avatar/' + response.id);
          return;
        }
        setStudentAvatar(response);
        setAvatarLevel(getAvatarLevel(response.xp, course.xpPerLevel));
        setUpAvatarXP(response.xp);
        setUpAvatarGold(response.gold);
        setUpAvatarHearts(response.life);
        setUpAvatarName(response.avatarName !== null ? response.avatarName : 'NA');
        const avatars = await fetch(`${process.env.PUBLIC_URL}/avatars.json`);
        const data = await avatars.json();
        const matchedAvatar = data.find((avatar: Avatar) => avatar.name === response.avatarName);
        setAvatarDescription(matchedAvatar.description);
      };
      if (signedIn && !user?.teacher && courseId) {
        setLoadingAvatar(true);
        fetchParticipant();
      }
      setLoadingAvatar(false);
    }
  }, [course]);

  useEffect(() => {
    setInitialRender(false);
  }, []);

  const generateTemporaryPassword = async () => {
    setLoading(true);
    setPasswordCopied(t('copyPassword'));
    await new Promise((resolve) => setTimeout(resolve, 500));
    const temporaryPassword = generateRandomPassword();
    setSelectedTemporaryPassword(temporaryPassword);
  };

  const handleModalClose = () => {
    // Handle closing animation
    setInfoModalIsClosing(true);
    setTimeout(() => {
      setInfoModalOpen(false);
      setInfoModalIsClosing(false);
    }, 300);

    // Reset variables
    setSelectedParticipant(null);
    setSelectedTemporaryPassword('********');
    setPasswordSet(false);
    setAvatarReset('false');
    setLoading(false);
  };

  // Function to generate a random password
  const generateRandomPassword = () => {
    const length = Math.floor(Math.random() * (12 - 8 + 1)) + 8; // Random length between 8 and 12
    const charset =
      'abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789!@#$%^&*()_+{}[]:;<>,.?/~\\-123456789';
    const specialChars = /[!@#$%^&*()_+{}[\]:;<>,.?~\\/-]/;
    let password = '';

    let hasLowerCase = false;
    let hasUpperCase = false;
    let hasNumber = false;
    let hasSpecialChar = false;

    while (!hasLowerCase || !hasUpperCase || !hasNumber || !hasSpecialChar) {
      password = '';
      for (let i = 0; i < length; i++) {
        const randomIndex = Math.floor(Math.random() * charset.length);
        password += charset[randomIndex];
      }
      hasLowerCase = /[a-z]/.test(password);
      hasUpperCase = /[A-Z]/.test(password);
      hasNumber = /[0-9]/.test(password);
      hasSpecialChar = new RegExp(`[${specialChars}]`).test(password);
    }
    return password;
  };

  // Copies generated password to clipboard
  const copyToClipboard = () => {
    navigator.clipboard.writeText(selectedTemporaryPassword);
    navigator.clipboard.readText();
    setPasswordCopied(t('passwordCopied'));
  };

  const participants = course?.participants || [];

  const [searchTerm, setSearchTerm] = useState('');

  // Handle the searchbar
  const filteredParticipants = participants
    .filter((participant) => participant.role === 'STUDENT')
    .filter((participant) => {
      const fullName = participant.user.firstName + ' ' + participant.user.lastName;
      const email = participant.user.email || '';

      return (
        fullName.toLowerCase().includes(searchTerm.toLowerCase()) ||
        email.toLowerCase().includes(searchTerm.toLowerCase())
      );
    });

  // Handle search input change and clear selected students
  const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(e.target.value); // Update the search term
    setSelectedStudents([]); // Clear the selected students when searching
  };

  // Check if all students are selected
  const areAllSelected =
    filteredParticipants?.length > 0 &&
    filteredParticipants?.every((participant) => selectedStudents.includes(participant.id));

  // Toggle checkbox for a specific student
  const toggleStudentSelection = (participantId: number) => {
    if (selectedStudents.includes(participantId)) {
      setSelectedStudents(selectedStudents.filter((id) => id !== participantId));
    } else {
      setSelectedStudents([...selectedStudents, participantId]);
    }
  };

  // Toggle "Check All" functionality
  const toggleSelectAll = () => {
    if (areAllSelected) {
      setSelectedStudents([]);
    } else {
      const allStudentIds = filteredParticipants?.map((participant) => participant.id);
      setSelectedStudents(allStudentIds ? allStudentIds : []);
    }
  };

  const openGiveRewardsModal = async (type: 'xp' | 'gold' | 'life'): Promise<void> => {
    setPointsType(type);
    setGiveRewardsModalOpen(true);
  };

  const closeGiveRewardsModal = async (): Promise<void> => {
    setGiveRewardsModalOpen(false);
    await refetch();
  };

  // Define a color for each student's circle based on their name
  const getColorFromName = (name: string): string => {
    let hash = 0;
    for (let i = 0; i < name.length; i++) {
      hash = name.charCodeAt(i) + ((hash << 5) - hash);
    }
    // Convert the hash to an hsl color
    return `hsl(${hash % 360}, 75%, 50%)`;
  };

  // Handle highlighting the text matching the search term. Color can be changed in css file
  const highlightSearchTerm = (text: string, searchTerm: string) => {
    if (!searchTerm) return text; // If no search term, return the original text

    const regex = new RegExp(`(${searchTerm})`, 'gi');
    const parts = text.split(regex);

    return parts.map((part, index) =>
      part.toLowerCase() === searchTerm.toLowerCase() ? (
        <span key={index} className='highlight'>
          {part}
        </span>
      ) : (
        part
      ),
    );
  };

  const deleteParticipant = async (participant: Participant) => {
    setDeleteLoading(true);
    const confirmation = confirm(
      t('confirmRemoveParticipant', {
        firstName: participant.user.firstName,
        lastName: participant.user.lastName,
      }),
    );

    if (confirmation) {
      const response = await fetchAPI(`/participants/${participant.id}`, {
        method: 'DELETE',
      });

      if (response.isSuccess) {
        handleModalClose();
        await refetch();
      }
    }
    setDeleteLoading(false);
  };

  const resetAvatar = async (participant: Participant) => {
    setResetLoading(true);
    const resetAvatar: UpdateAvatar = {
      id: participant.id,
      avatar: '',
      avatarName: '',
    };
    const start = Date.now();
    const response = await updateAvatar(resetAvatar);
    const elapsed = Date.now() - start;
    const minDuration = 500; // Minimum duration in ms

    if (elapsed < minDuration) {
      await new Promise((resolve) => setTimeout(resolve, minDuration - elapsed));
    }
    if (response) {
      setAvatarReset('true');
    }
    setResetLoading(false);
  };

  return (
    <>
      <div className='mw-100 d-flex flex-column h-100 p-3'>
        {isLoading || loadingAvatar || !(course && course.participants) ? (
          <Loading />
        ) : (
          <div className='d-flex flex-column h-100 mw-100' data-testid='coursePage'>
            {/* Page for a TEACHER */}
            {signedIn && user?.teacher ? (
              <>
                <h1>
                  {t('courseIntroText')} {course.name}
                </h1>
                {/* Shows name of the teacher for the course */}
                <h3>
                  {t('teacher')}:{' '}
                  {course.participants.find((p) => p.role === 'TEACHER')?.user.firstName}{' '}
                  {course.participants.find((p) => p.role === 'TEACHER')?.user.lastName}
                </h3>
                <div>
                  <h1 className='mt-3'>{t('students')}</h1>
                  {course.participants.some((p) => p.role === 'STUDENT') ? (
                    <div className='d-flex flex-column' style={{ height: '65vh' }}>
                      <div>
                        <button
                          className='btn btn-primary m-1'
                          data-testid='giveXpButton'
                          disabled={selectedStudents.length === 0}
                          onClick={() => openGiveRewardsModal('xp')}
                        >
                          <img
                            src={`${process.env.PUBLIC_URL}/xp.png`}
                            alt='XP Icon'
                            className='icon-image'
                          />
                        </button>
                        <button
                          className='btn btn-primary m-1'
                          data-testid='goldButton'
                          disabled={selectedStudents.length === 0}
                          onClick={() => openGiveRewardsModal('gold')}
                        >
                          <img
                            src={`${process.env.PUBLIC_URL}/gold-coins.png`}
                            alt='Or Icon'
                            className='icon-image'
                          />
                        </button>
                        <button
                          className='btn btn-primary m-1'
                          data-testid='lifeButton'
                          disabled={selectedStudents.length === 0}
                          onClick={() => openGiveRewardsModal('life')}
                        >
                          <img
                            src={`${process.env.PUBLIC_URL}/heart.png`}
                            alt='Life Icon'
                            className='icon-image'
                          />
                        </button>
                      </div>
                      <div className='my-3'>
                        <input
                          type='search'
                          className='form-control w-25 bg-light border-dark text-dark shadow-sm'
                          placeholder={t('search')}
                          data-testid='searchInput'
                          value={searchTerm}
                          onChange={handleSearchChange}
                        />
                      </div>
                      <div className='flex-grow-1' style={{ overflowY: 'auto' }}>
                        <table className='table'>
                          <thead className='sticky'>
                            <tr>
                              {/* "Check All" box */}
                              <th className='w-5'>
                                <input
                                  className='form-check-input me-1'
                                  type='checkbox'
                                  data-testid='selectAllCheckbox'
                                  checked={areAllSelected}
                                  onChange={toggleSelectAll}
                                />
                              </th>
                              <th>{t('name')}</th>
                              <th>{t('email')}</th>
                              <th>{t('XP')}</th>
                              <th>{t('gold')}</th>
                              <th>{t('life')}</th>
                              <th>{t('actions')}</th>
                            </tr>
                          </thead>
                          <tbody>
                            {/* Shows all STUDENTs in course */}
                            {/* Copy the following template to simulate having more students in a class, for testing
                          {[...filteredParticipants, ...filteredParticipants]?.map(
                            (participant, index) => (
                              <tr key={`${participant.id}-${index}`}>
                              */}
                            {filteredParticipants
                              ?.sort((a, b) =>
                                a.user.firstName
                                  .toLowerCase()
                                  .localeCompare(b.user.firstName.toLowerCase()),
                              )
                              .map((participant) => (
                                <tr key={participant.id}>
                                  {/* Checkbox for each student */}
                                  <td className='align-middle'>
                                    <input
                                      className='form-check-input'
                                      type='checkbox'
                                      data-testid={`studentCheckbox-${participant.id}`}
                                      checked={selectedStudents.includes(participant.id)}
                                      onChange={() => toggleStudentSelection(participant.id)}
                                    />
                                  </td>
                                  <td className='align-middle'>
                                    <div className='d-flex align-items-center'>
                                      <div
                                        className='initial-circle me-2'
                                        style={{
                                          backgroundColor: getColorFromName(
                                            participant.user.firstName + participant.user.lastName,
                                          ),
                                        }}
                                      >
                                        {participant.user.firstName.charAt(0)}
                                        {participant.user.lastName.charAt(0)}
                                      </div>
                                      <div>
                                        {highlightSearchTerm(
                                          participant.user.firstName +
                                            ' ' +
                                            participant.user.lastName,
                                          searchTerm,
                                        )}
                                      </div>
                                    </div>
                                  </td>
                                  <td className='align-middle'>
                                    {highlightSearchTerm(participant.user.email || '', searchTerm)}
                                  </td>
                                  <td className='align-middle'>{participant.xp}</td>
                                  <td className='align-middle'>{participant.gold}</td>
                                  <td className='align-middle'>{participant.life}</td>
                                  <td>
                                    <button
                                      type='button'
                                      className='btn btn-primary align-middle'
                                      data-testid={`gearButton-${participant.id}`}
                                      onClick={() => handleGearClick(participant)}
                                    >
                                      <FontAwesomeIcon icon={faGear} />
                                    </button>
                                  </td>
                                </tr>
                              ))}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  ) : (
                    <h6>{t('noStudentsMessage')}</h6>
                  )}
                </div>
              </>
            ) : (
              <>
                {/* Page for student */}
                <div className='d-flex flex-lg-row flex-grow-1 h-100 mw-100'>
                  <div className='d-flex flex-lg-row flex-grow-1 h-100 mw-50 align-items-center justify-content-center'>
                    {/* Display Avatar image on one half of the screen */}
                    <div className='w-100 h-75 d-flex flex-column align-items-center justify-content-center'>
                      <h2 className='mb-5'>{avatarName}</h2>
                      <img
                        src={`${process.env.PUBLIC_URL}/${studentAvatar?.avatar}`}
                        alt='Avatar'
                        style={{
                          objectFit: 'contain',
                          width: 'auto',
                          height: '100%',
                        }}
                      />
                    </div>
                  </div>

                  <div
                    className='w-50 h-100 d-flex align-items-center flex-column'
                    style={{
                      backgroundColor: 'rgba(0, 0, 0, 0.2)',
                    }}
                  >
                    <h2>
                      {t('courseIntroText')} {course.name}
                    </h2>
                    {/* Shows name of the teacher for the course */}
                    <h3>
                      {t('teacher')}:{' '}
                      {course.participants.find((p) => p.role === 'TEACHER')?.user.firstName}{' '}
                      {course.participants.find((p) => p.role === 'TEACHER')?.user.lastName}
                    </h3>
                    {/* We display Avatar information and other content on the other half of the screen */}
                    <div id='carouselExample' className='carousel slide d-flex flex-grow-1 w-100'>
                      <div className='carousel-inner h-100'>
                        <div className='carousel-item active h-100'>
                          <div className='d-flex flex-column justify-content-center align-items-center h-100'>
                            {/* Display info on level, xp, gold and hearts */}
                            <div className='container d-flex justify-content-center align-items-center'>
                              {' '}
                              <div
                                className='progress-circle'
                                style={{
                                  background: `conic-gradient(#007bff 0%, #007bff ${avatarLevel}%, #d9d9d9 ${avatarLevel}%)`,
                                }}
                              >
                                {/* LEVEL */}
                                <div className='progress-value text-center'>
                                  <div className='container'>
                                    <div className='row'>
                                      <div className='col'>
                                        <h4 className='mb-0'>{t('level')}</h4>
                                      </div>
                                    </div>
                                    <div className='row'>
                                      <div className='col'>
                                        <h4>{avatarLevel}</h4>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className='container'>
                              {/* XP */}
                              <div className='d-flex justify-content-between align-items-center progress-headline'>
                                <h3 className='progress-title mb-0 mt-auto'>
                                  {t('XP')} - {studentXP}
                                </h3>
                                <img
                                  src={`${process.env.PUBLIC_URL}/xp.png`}
                                  alt='XP Icon'
                                  className='icon'
                                />
                              </div>
                              <div className='progress'>
                                <div
                                  className='progress-bar progress-bar-danger progress-bar-striped active'
                                  style={{ width: xpPercentage, backgroundColor: '#28a745' }}
                                ></div>
                              </div>
                              {/* GOLD */}
                              <div className='d-flex justify-content-between align-items-center progress-headline'>
                                <h3 className='progress-title mb-0 mt-auto'>
                                  {t('gold')} - {studentGold}
                                </h3>
                                <img
                                  src={`${process.env.PUBLIC_URL}/gold-coins.png`}
                                  alt='Gold Icon'
                                  className='icon'
                                />
                              </div>
                              {/* HEARTS */}
                              <div className='progress'>
                                <div
                                  className='progress-bar progress-bar-info progress-bar-striped active'
                                  style={{ width: goldPercentage, backgroundColor: '#FFD700' }}
                                ></div>
                              </div>
                              <div className='d-flex justify-content-between align-items-center progress-headline'>
                                <h3 className='progress-title mb-0 mt-auto'>
                                  {t('life')} - {studentHearts}
                                </h3>
                                <img
                                  src={`${process.env.PUBLIC_URL}/heart.png`}
                                  alt='Heart Icon'
                                  className='icon'
                                />
                              </div>

                              <div className='progress'>
                                <div
                                  className='progress-bar progress-bar-info progress-bar-striped active'
                                  style={{ width: heartsPercentage, backgroundColor: '#ff4d4d' }}
                                ></div>
                              </div>
                            </div>
                          </div>
                        </div>
                        {/* Display description of avatar, section to be developped */}
                        <div className='carousel-item h-100'>
                          <div className='d-flex justify-content-center align-items-center h-100'>
                            {avatarDescription}
                          </div>
                        </div>
                      </div>
                      <div className='d-flex justify-content-between align-items-center w-100 position-absolute top-50 translate-middle-y'>
                        <button
                          className='carousel-control-prev'
                          type='button'
                          data-bs-target='#carouselExample'
                          data-bs-slide='prev'
                          style={{ width: '50px', height: '50px' }}
                        >
                          <span
                            className='carousel-control-prev-icon'
                            aria-hidden='true'
                            style={{ width: '100%', height: '100%' }}
                          ></span>
                          <span className='visually-hidden'>Previous</span>
                        </button>

                        <button
                          className='carousel-control-next'
                          type='button'
                          data-bs-target='#carouselExample'
                          data-bs-slide='next'
                          style={{ width: '50px', height: '50px' }}
                        >
                          <span
                            className='carousel-control-next-icon'
                            aria-hidden='true'
                            style={{ width: '100%', height: '100%' }}
                          ></span>
                          <span className='visually-hidden'>Next</span>
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </>
            )}
          </div>
        )}
        {/* Modal to show and manage student info for prof */}
        {infoModalOpen && (
          <div
            className='modal-backdrop show'
            style={{ backgroundColor: 'rgba(0, 0, 0, 1)' }}
          ></div>
        )}
        <div
          className={`modal fade ${infoModalOpen ? 'show' : ''} ${infoModalIsClosing ? 'modal-drop-out' : 'modal-drop-in'}`}
          tabIndex={-1}
          aria-hidden='true'
          style={{ display: infoModalOpen || infoModalIsClosing ? 'block' : 'none' }}
          role={infoModalOpen ? 'dialog' : ''}
        >
          <div className='modal-dialog modal-lg modal-dialog-centered'>
            <div className='modal-content'>
              <div className='card user-card-full'>
                <div className='row m-l-0 m-r-0'>
                  <div className='col-sm-4 bg-c-lite-green user-profile p-0'>
                    <div className='card-block text-center text-white'>
                      <div className='m-b-25'>
                        <img
                          src='https://img.icons8.com/bubbles/100/000000/user.png'
                          className='img-radius mx-auto'
                          alt='User-Profile-Image'
                        />
                      </div>
                      <h6 className='f-w-600'>
                        {selectedParticipant?.user.firstName} {selectedParticipant?.user.lastName}
                      </h6>
                      <i className=' mdi mdi-square-edit-outline feather icon-edit m-t-10 f-16'></i>
                    </div>
                  </div>
                  <div className='col-sm-8'>
                    <div className='card-block'>
                      <button
                        type='button'
                        className='btn btn-close close'
                        data-bs-dismiss='modal'
                        aria-label='Close'
                        data-testid='removeModal'
                        onClick={handleModalClose}
                      ></button>
                      <h6 className='m-b-20 p-b-5 b-b-default f-w-600'>{t('information')}</h6>
                      <div className='row'>
                        <div className='col-sm-6'>
                          <p className='m-b-10 f-w-600'>{t('email')}</p>
                          <h6 className='text-muted f-w-400'>{selectedParticipant?.user.email}</h6>
                        </div>
                        <div className='col-sm-6'>
                          <p className='m-b-10 f-w-600'>{t('username')}</p>
                          <h6 className='text-muted f-w-400'>
                            {selectedParticipant?.user.username}
                          </h6>
                        </div>
                      </div>
                      <h6 className='m-b-20 m-t-40 p-b-5 b-b-default f-w-600'>
                        {t('setNewPassword')}
                      </h6>
                      <div className='row'>
                        {loading ? (
                          <div className='col-sm-12'>
                            <Loading insideModal={'coursePage'} />
                          </div>
                        ) : (
                          <>
                            {/* Password section */}
                            <div className='col-sm-6'>
                              {passwordSet ? (
                                <>
                                  {error ? (
                                    <>
                                      <div className='alert alert-danger' role='alert'>
                                        {t('newTempPasswordError')}
                                      </div>
                                    </>
                                  ) : (
                                    <>
                                      {/* Shows new password */}
                                      <p className='m-b-10 f-w-600'>{t('newPassword')}</p>
                                      <a
                                        data-tooltip-id='my-tooltip'
                                        data-tooltip-content={passwordCopied}
                                      >
                                        <h6
                                          className='text-muted f-w-400 pointer'
                                          style={{ display: 'inline' }}
                                          data-testid='copyToClipboard'
                                          onClick={copyToClipboard}
                                        >
                                          {selectedTemporaryPassword}
                                        </h6>
                                      </a>
                                      <Tooltip id='my-tooltip' />
                                    </>
                                  )}
                                </>
                              ) : (
                                <>
                                  {/* Password not Shown */}
                                  <p className='m-b-10 f-w-600'>{t('password')}</p>
                                  <h6 className='text-muted f-w-400 pointer'>********</h6>
                                </>
                              )}
                            </div>
                            <div className='col-sm-6'>
                              {/* Button to generate new password */}
                              <button
                                className='btn btn-primary btn-sm'
                                data-testid='generateNewPassword'
                                onClick={generateTemporaryPassword}
                              >
                                {t('genNewPassword')}
                              </button>
                            </div>
                          </>
                        )}
                      </div>

                      <h6 className='m-b-20 m-t-40 p-b-5 b-b-default f-w-600'>
                        {t('resetAvatar')}
                      </h6>
                      {/* This Part allows a teacher to reset a student's avatar to allow them to pick new one */}
                      <div className='row'>
                        {resetLoading ? (
                          <div className='col-sm-12'>
                            <Loading insideModal={'coursePage'} />
                          </div>
                        ) : avatarReset === 'false' ? (
                          <div className='d-flex justify-content-start'>
                            {selectedParticipant ? (
                              <button
                                type='button'
                                className='btn btn-primary'
                                onClick={() => resetAvatar(selectedParticipant)}
                              >
                                {t('resetAvatar')}
                              </button>
                            ) : (
                              <div></div>
                            )}
                          </div>
                        ) : avatarReset === 'true' ? (
                          <div className='col-sm-12'>
                            <div className='alert alert-success'>{t('resetAvatarSucces')}</div>
                          </div>
                        ) : (
                          <div className='col-sm-12'>
                            <div className='alert alert-danger'>{t('resetAvatarError')}</div>
                          </div>
                        )}
                      </div>

                      <h6 className='m-b-20 m-t-40 p-b-5 b-b-default f-w-600'>
                        {t('removeParticipant')}
                      </h6>
                      {/* This Part will allow a teacher to remove a student from the class */}
                      <div className='row'>
                        {deleteLoading ? (
                          <div className='col-sm-12'>
                            <Loading insideModal={'coursePage'} />
                          </div>
                        ) : (
                          <div className='d-flex justify-content-start'>
                            {selectedParticipant ? (
                              <button
                                type='button'
                                className='btn btn-danger'
                                data-testid='removeParticipant'
                                onClick={() => deleteParticipant(selectedParticipant)}
                              >
                                {t('removeParticipant')}
                              </button>
                            ) : (
                              <div></div>
                            )}
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <GiveRewardsModal
        isOpen={giveRewardsModalOpen}
        onClose={closeGiveRewardsModal}
        pointsType={pointsType}
        participantList={selectedStudents}
      />
    </>
  );
};

export default CoursePage;
