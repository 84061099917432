import { useEffect, useState } from 'react';
import { getParticipant, updateAvatar } from '../../../utils/httpRequests';
import { useNavigate, useParams } from 'react-router-dom';
import Loading from '../../../components/Loading';
import { useTranslation } from 'react-i18next';

interface Avatar {
  name: string;
  image: string;
  description: string;
}

const AvatarSelection = () => {
  const { t } = useTranslation('global');
  const { participantId } = useParams<{ participantId: string }>();
  const { courseId } = useParams<{ courseId: string }>();
  const [avatars, setAvatars] = useState<Avatar[]>([]);
  const [selectedAvatar, setSelectedAvatar] = useState<Avatar | null>(null);
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(true);

  // Set's selected Avatar once confirmed.
  const handleSelect = async () => {
    if (selectedAvatar !== null) {
      const response = await updateAvatar({
        id: Number(participantId),
        avatar: selectedAvatar.image,
        avatarName: selectedAvatar.name,
      });
      if (response) {
        navigate('/courses/' + courseId);
      }
    } else {
      alert(t('errorSelectedAvatar'));
    }
  };

  const fetchAvatars = async () => {
    setIsLoading(true);
    try {
      const response = await fetch(`${process.env.PUBLIC_URL}/avatars.json`);
      const data = await response.json();
      setAvatars(data);
      setSelectedAvatar(data[0]); // Set the first avatar as default selection
    } catch (error) {
      console.error('Failed to load avatars:', error);
    }
    setIsLoading(false);
  };

  useEffect(() => {
    const fetchParticipant = async () => {
      setIsLoading(true);
      const participant = await getParticipant(participantId!);

      // If participant already has an avatar selected it redirects them to the course page.
      if (participant) {
        if (!(!participant.avatar || participant.avatar === '')) {
          navigate('/courses/' + courseId);
        }
        setIsLoading(false);
      }
    };

    fetchParticipant();
    fetchAvatars();
  }, [participantId]);

  return (
    <div className='d-flex flex-column h-100'>
      {/* This page allows student to select the Avatar they wish to use */}
      {isLoading ? (
        <Loading />
      ) : (
        <>
          <div
            style={{
              height: '65%',
              backgroundImage: `url(${process.env.PUBLIC_URL}/ship.png)`,
              backgroundSize: 'cover',
              backgroundPosition: 'center',
            }}
            className='d-flex flex-column'
            data-testid='avatarSelection'
          >
            <div
              className='py-3'
              style={{
                backgroundColor: '#000000ca',
              }}
            >
              <h1 className='text-center text-white p-0'>{t('selectAvatar')}</h1>
            </div>
            <div className='d-flex flex-lg-row flex-grow-1 h-50 p-5'>
              <div className='w-50 h-100 d-flex align-items-center justify-content-center'>
                {selectedAvatar !== null ? (
                  <>
                    {/* This displays the currently selected avatar image */}
                    <img
                      src={`${process.env.PUBLIC_URL}/${selectedAvatar.image !== null ? selectedAvatar.image : 'User.png'}`}
                      alt='Avatar'
                      style={{
                        objectFit: 'contain',
                        width: 'auto',
                        height: '100%',
                      }}
                    />
                  </>
                ) : (
                  <>
                    {/* If there's no avatar selected which should never happen it displays a default user icon image */}
                    <img
                      src={`${process.env.PUBLIC_URL}/User.png`}
                      alt='Avatar'
                      style={{
                        objectFit: 'contain',
                        width: 'auto',
                        height: '100%',
                      }}
                    />
                  </>
                )}
              </div>
              <div
                className='w-50 h-100 d-flex align-items-center justify-content-center flex-column'
                style={{
                  backgroundColor: '#000000ca',
                }}
              >
                {selectedAvatar !== null ? (
                  <>
                    {/* Display's the selected Avatar info */}
                    <h1 className='text-center text-white p-0'>{selectedAvatar.name}</h1>
                    <p className='text-center text-white p-0'>{selectedAvatar.description}</p>
                  </>
                ) : (
                  <>
                    {/* Information displayed in case there's no avatar slecetd which should never happen */}
                    <h1 className='text-center text-white p-0'>{t('N/A')}</h1>
                    <p className='text-center text-white p-0'>{t('N/A')}</p>
                  </>
                )}

                <button
                  className='d-inline-flex align-items-center btn btn-primary btn-lg px-4 rounded-pill'
                  onClick={handleSelect}
                >
                  {t('select')}
                </button>
              </div>
            </div>
          </div>
          <div
            style={{
              height: '35%',
            }}
            className='d-flex flex-column'
          >
            <div className='h-100 overflow-x-scroll'>
              <div className='row flex-row flex-nowrap h-100'>
                {/* Display's all possible Avatars a student can select */}
                {avatars.map((avatar, index) => (
                  <div
                    key={index}
                    className='p-3 h-100 col-6 col-md-3 col-lg-2 d-flex align-items-center'
                    onClick={() => setSelectedAvatar(avatar)}
                    style={{ cursor: 'pointer' }}
                  >
                    <img
                      src={`${process.env.PUBLIC_URL}/${avatar.image}`}
                      alt={avatar.name}
                      style={{
                        width: 'auto',
                        maxHeight: '100%',
                      }}
                    />
                  </div>
                ))}
              </div>
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default AvatarSelection;
