import { useWizard } from 'react-use-wizard';
import { useTranslation } from 'react-i18next';
import RenderUnsafeHTML from './RenderUnsafeHTML';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft, faArrowRight } from '@fortawesome/free-solid-svg-icons';

const BasicTextWizardStep = ({ text, closeModal }: { text: string; closeModal?: () => void }) => {
  const { previousStep, nextStep, isFirstStep, isLastStep } = useWizard(); // Contains info about the current step and refs to the previous and next steps
  const { t } = useTranslation('global');

  // Display the main content followed by the appropriate buttons at the bottom of the modal
  return (
    <div data-testid='basicTextWizardStep'>
      <div className='mb-2'>
        <RenderUnsafeHTML html={text} />
      </div>

      <div className='d-flex gap-2 pb-4'>
        {!isFirstStep && (
          <button type='button' className='btn btn-secondary' onClick={previousStep}>
            <FontAwesomeIcon icon={faArrowLeft} className='me-1' /> {t('previousStep')}
          </button>
        )}
        {!isLastStep && (
          <button type='button' className='btn btn-primary' onClick={nextStep}>
            {t('nextStep')} <FontAwesomeIcon icon={faArrowRight} className='ms-1' />
          </button>
        )}
        {!!closeModal && (
          <button type='button' className='btn btn-primary' onClick={closeModal}>
            {t('closeModal')}
          </button>
        )}
      </div>
    </div>
  );
};

export default BasicTextWizardStep;
