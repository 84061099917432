import { fetchAPI } from './httpRequests';
import { useAuth } from './stores';

export interface SignInBody {
  emailOrUsername: string;
  password: string;
}

export interface SignUpBody {
  email?: string;
  password: string;
  role: string;
  firstName: string;
  lastName: string;
  username: string;
  confirmPassword: string;
  captchaToken: string;
  accessCode?: string;
}

interface AuthenticatorResponse {
  access_token: string;
  refresh_token: string;
  user: User;
  valid: boolean;
}

export interface User {
  id: number;
  firstName: string;
  lastName: string;
  email: string;
  username: string;
  role: string;
  teacher: boolean;
}

interface ValidateTokenResponseValid {
  valid: true;
  user: User;
}

interface ValidateTokenResponseInvalid {
  valid: false;
}

type ValidateTokenResponse = ValidateTokenResponseInvalid | ValidateTokenResponseValid;

// Back-end errors that will show same error message for sign in
const commonErrorList: string[] = [
  "Email is required for Teacher's accounts.",
  'You are not allowed to create an admin account.',
  'Email and username are both null',
];

// Check if string is JSON string
function isJSONString(str: string): boolean {
  try {
    JSON.parse(str);
    return true;
  } catch (e) {
    return false;
  }
}

const handleNewTokens = (response: AuthenticatorResponse): void => {
  localStorage.setItem('access_token', response.access_token);
  localStorage.setItem('refresh_token', response.refresh_token);
  localStorage.setItem('user', JSON.stringify(response.user));
  useAuth.setState({ signedIn: true, user: response.user });
};

// removes users token (sign out user)
export const removePreviousTokens = (): void => {
  localStorage.removeItem('access_token');
  localStorage.removeItem('refresh_token');
  localStorage.removeItem('user');
  useAuth.setState({ signedIn: false, user: null });
  window.dispatchEvent(new Event('tokensRemoved')); // allows to make sure we remove all previous states
};

// function for user to sign in
export const signIn = async (body: SignInBody): Promise<boolean> => {
  const response = await fetchAPI<AuthenticatorResponse>('/auth/sign-in', {
    method: 'POST',
    body,
  });

  if (response.isSuccess) {
    handleNewTokens(response.data);
    return true;
  }

  removePreviousTokens();
  return false;
};

// function for user to sign up
export const signUp = async (body: SignUpBody): Promise<string> => {
  const response = await fetchAPI<AuthenticatorResponse>('/auth/sign-up', {
    method: 'POST',
    body,
  });

  if (response.isSuccess) {
    handleNewTokens(response.data);
    return 'success';
  }

  removePreviousTokens();

  // Checks if response can be parsed
  if (isJSONString(response.error)) {
    const parsedJson = JSON.parse(response.error);
    // Checks if response can be parsed
    if (isJSONString(parsedJson.message)) {
      const list = JSON.parse(parsedJson.message).errors;
      // Checks if error with email or username
      if (list.includes('EMAIL') && list.includes('USERNAME')) {
        return 'Email and Username error';
      } else if (list.includes('EMAIL')) {
        return 'Email error';
      } else if (list.includes('USERNAME')) {
        return 'Username error';
      }
    } else {
      // Checks if it's a known error
      if (commonErrorList.includes(parsedJson.message)) {
        return 'Common error';
      } else if ('Access code for class invalid.' === parsedJson.message) {
        return 'Access code for class invalid.';
      } else if ('User failed the captcha verification' === parsedJson.message) {
        return 'User failed the captcha verification';
      }
    }
    // Returns unknown error message
    return parsedJson.message;
  } else {
    return response.error;
  }
};

const refreshToken = async (): Promise<boolean> => {
  try {
    const response = await fetchAPI<AuthenticatorResponse>('/auth/refresh-token', {
      method: 'POST',
      useRefreshToken: true,
    });

    if (response.isSuccess && response.data.valid) {
      handleNewTokens(response.data);
      return true;
    } else {
      removePreviousTokens();
      return false;
    }
  } catch (e) {
    return false;
  }
};

export const validateAndRefreshCurrentToken = async (): Promise<boolean> => {
  try {
    const response = await fetchAPI<ValidateTokenResponse>('/auth/validate-token', {
      method: 'POST',
    });

    if (response.isSuccess) {
      if (!response.data.valid) {
        return await refreshToken();
      } else {
        localStorage.setItem('user', JSON.stringify(response.data.user));
        useAuth.setState({ signedIn: true, user: response.data.user });
        return true;
      }
    } else {
      return false;
    }
  } catch (e) {
    return false;
  }
};
