import Modal from 'react-modal';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faClose } from '@fortawesome/free-solid-svg-icons';
import { useEffect } from 'react';
import { Concept } from '../quests/Quests';
import { Wizard } from 'react-use-wizard';
import ConceptWizardStep1 from './formSteps/ConceptWizardStep1';
import { useConceptFormStore } from '../../../utils/stores';
import ConceptWizardStep2 from './formSteps/ConceptWizardStep2';
import ConceptWizardQuestions from './formSteps/ConceptWizardQuestions';
import { useTranslation } from 'react-i18next';

export type ConceptFileSubmissionTypes =
  | 'NONE'
  | 'FOR_EACH_QUESTION'
  | 'FOR_SOME_QUESTIONS'
  | 'PER_CONCEPT';

export interface FormConceptPart1 {
  title: string;
  learning: string;
  practice: string;
  fileSubmissionType: ConceptFileSubmissionTypes;
}

export interface FormConceptPart2 {
  hasDifferentiation: boolean;
  differentiationLearning?: string;
  differentiationPractice?: string;
}

export type FormConcept = FormConceptPart1 & FormConceptPart2;

interface ConceptModalProps {
  isOpen: boolean; // State of the modal
  onClose: (id: number | null) => void; // Closing function
  editConcept?: Concept | null; // Defines if we edit or create a concept
}

const ConceptModal = ({ isOpen, onClose, editConcept }: ConceptModalProps) => {
  const { t } = useTranslation('global');

  const { updateId, updatePart1, updatePart2, id } = useConceptFormStore(); // Used to save the state of each part of the form

  useEffect(() => {
    // Update the ID if it exists
    updateId(editConcept?.id ?? null);

    // Update Part 1 of the modal, if it exists
    updatePart1(
      editConcept
        ? {
            title: editConcept.title,
            learning: editConcept.learning,
            practice: editConcept.practice,
            fileSubmissionType: editConcept.fileSubmissionType,
          }
        : null,
    );

    // Update Part 2 of the modal, if it exists
    updatePart2(
      editConcept
        ? {
            ...(editConcept.hasDifferentiation
              ? {
                  hasDifferentiation: editConcept.hasDifferentiation,
                  differentiationLearning: editConcept.differentiationLearning,
                  differentiationPractice: editConcept.differentiationPractice,
                }
              : {
                  hasDifferentiation: editConcept.hasDifferentiation,
                  differentiationLearning: '',
                  differentiationPractice: '',
                }),
          }
        : null,
    );
  }, [editConcept]);

  const closeModal = (): void => {
    onClose(id);
  };

  // This modal allows a teacher to create and edit a concept
  return (
    <Modal isOpen={isOpen} onRequestClose={closeModal} contentLabel='Concept Modal'>
      <div className='w-100 h-100' data-testid='conceptModal'>
        <div className='d-flex w-100 justify-content-between'>
          <div>
            <h2>{editConcept ? t('concept.edit') : t('concept.create')}</h2>
          </div>
          <button onClick={closeModal} className='btn btn-primary'>
            <FontAwesomeIcon icon={faClose} />
          </button>
        </div>

        <Wizard>
          <ConceptWizardStep1 />
          <ConceptWizardStep2 />
          <ConceptWizardQuestions closeModal={closeModal} />
        </Wizard>
      </div>
    </Modal>
  );
};

Modal.setAppElement('#root');

export default ConceptModal;
