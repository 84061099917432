import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

const NotFound = () => {
  const { t } = useTranslation('global');

  return (
    <div
      className='d-flex flex-column align-items-center justify-content-center mt-5'
      data-testid='not-found'
    >
      <h3 data-testid='404NotFound'>{t('404NotFound')}</h3>
      <Link to={'/'} className='btn btn-primary my-2'>
        {t('goHome')}
      </Link>
    </div>
  );
};
export default NotFound;
