import RenderUnsafeHTML from '../../../../components/RenderUnsafeHTML';
import Modal from 'react-modal';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faClose } from '@fortawesome/free-solid-svg-icons';

export interface BasicTextWizardStepData {
  type: 'start' | 'end';
  title?: string;
  text: string;
}

const BasicTextWizardStep = ({
  isOpen,
  onClose,
  data,
}: {
  isOpen: boolean;
  onClose: () => void;
  data: BasicTextWizardStepData;
}) => {
  const closeModal = () => {
    onClose();
  };

  return (
    <>
      {/* This modal is for the intro and conclusion of a quest */}
      <Modal isOpen={isOpen} onRequestClose={closeModal}>
        <div className='w-100 h-100' data-testid='startEndModal'>
          <div className='d-flex w-100 justify-content-between'>
            {data.title ? (
              <div>
                <h2>{data.title}</h2>
              </div>
            ) : (
              <div></div>
            )}
            <button onClick={closeModal} className='btn btn-primary'>
              <FontAwesomeIcon icon={faClose} />
            </button>
          </div>
          <div className='mb-2'>
            <RenderUnsafeHTML html={data.text} />
          </div>
        </div>
      </Modal>
    </>
  );
};

export default BasicTextWizardStep;
