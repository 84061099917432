import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import i18nEN from './locales/en/global.json';
import i18nFR from './locales/fr/global.json';
import i18next from 'i18next';
import { I18nextProvider } from 'react-i18next';
import { BrowserRouter } from 'react-router-dom';
import { QueryClient, QueryClientProvider } from 'react-query';
import 'bootstrap/dist/js/bootstrap.bundle.min.js';
import 'bootstrap/dist/css/bootstrap.min.css';

// Handle the translations of the text content
const savedLanguage = localStorage.getItem('language') || 'fr';

i18next.init({
  interpolation: { escapeValue: false },
  lng: savedLanguage,
  fallbackLng: 'fr', // Set the default language to French
  resources: {
    en: {
      global: i18nEN,
    },
    fr: {
      global: i18nFR,
    },
  },
});

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
    },
  },
});

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);
root.render(
  <React.StrictMode>
    <BrowserRouter>
      <QueryClientProvider client={queryClient}>
        <I18nextProvider i18n={i18next}>
          <App />
        </I18nextProvider>
      </QueryClientProvider>
    </BrowserRouter>
  </React.StrictMode>,
);
