import Modal from 'react-modal';
import { QuestLogStatus, QuestStep, Story } from '../../pages/teacher/quests/Quests';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faClose } from '@fortawesome/free-solid-svg-icons';
import { useWizard, Wizard } from 'react-use-wizard';
import BasicTextWizardStep from '../BasicTextWizardStep';
import StudentQuestQuizModal from './StudentQuestQuizModal';
import { useTranslation } from 'react-i18next';

interface StudentQuestStepModalProps {
  story: Story; // Contains the Story passed to the modal
  questStep: QuestStep; // Contains the current QuestStep
  isOpen: boolean; // State of the modal
  status: QuestLogStatus; // Status of the QuestStep
  onClose: () => void; // Closing function
  updateCompletionStatus: () => void; // Function to update the completion status
}

const StudentQuestStepModal = ({
  isOpen,
  onClose,
  questStep,
  status,
  story,
  updateCompletionStatus,
}: StudentQuestStepModalProps) => {
  const closeModal = () => {
    onClose();
  };

  const showDifferentiation = status === 'COMPLETED_WITH_DIFFERENTIATION' || status === 'FAILED';

  const WizardHeader = () => {
    const { t } = useTranslation('global');
    const { activeStep, stepCount } = useWizard();

    // Check which step the person completing the quest is on
    const wizardStepTitle = () => {
      switch (activeStep) {
        case 0:
          return t('setting');
        case 1:
          return t('learning');
        case 2:
          return t('practice');
        case 3:
          return t('quiz');
        case 4:
          if (stepCount > 5) {
            return t('learning');
          } else {
            return t('epilogue');
          }
        case 5:
          return t('practice');
        case 6:
          return t('quiz');
        case 7:
          return t('epilogue');
        default:
          return '';
      }
    };

    return (
      <div className='d-flex w-100 justify-content-between'>
        <div>
          <h2>
            {questStep.concept.title} - {wizardStepTitle()}
          </h2>
        </div>
        <button onClick={closeModal} className='btn btn-primary'>
          <FontAwesomeIcon icon={faClose} />
        </button>
      </div>
    );
  };

  // Modal used to complete a quest
  return (
    <Modal isOpen={isOpen} onRequestClose={closeModal} contentLabel='Create/Update Quest Modal'>
      <div className='w-100 h-100' data-testid='studentQuestStepModal'>
        {/* Pass the pages of the modal in order */}
        <Wizard header={<WizardHeader />}>
          <BasicTextWizardStep text={story.setting} />
          <BasicTextWizardStep text={questStep.concept.learning} />
          <BasicTextWizardStep text={questStep.concept.practice} />
          <StudentQuestQuizModal
            updateCompletionStatus={updateCompletionStatus}
            questStep={questStep}
            status={status}
            differentiation={false}
          />
          {/* Check's if the following steps should be a part of the quest, only if differentiation is activated */}
          {showDifferentiation && questStep.concept.hasDifferentiation && (
            <BasicTextWizardStep text={questStep.concept.differentiationLearning} />
          )}
          {showDifferentiation && questStep.concept.hasDifferentiation && (
            <BasicTextWizardStep text={questStep.concept.differentiationPractice} />
          )}
          {showDifferentiation && (
            <StudentQuestQuizModal
              updateCompletionStatus={updateCompletionStatus}
              questStep={questStep}
              status={status}
              differentiation={true}
            />
          )}
          <BasicTextWizardStep text={story.epilogue} closeModal={closeModal} />
        </Wizard>
      </div>
    </Modal>
  );
};

export default StudentQuestStepModal;
