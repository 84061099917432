import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPencil, faTrash } from '@fortawesome/free-solid-svg-icons';
import { Story } from '../../quests/Quests';

interface StoryCardProps {
  story: Story;
  updateStory: (story: Story) => void;
  deleteStory: (story: Story) => void;
}

const StoryCard = ({ story, updateStory, deleteStory }: StoryCardProps) => {
  return (
    <div className='card my-2'>
      <div className='card-body d-flex align-items-center justify-content-between'>
        <h5 className='card-title'>{story.title}</h5>
        <div className='d-flex gap-2'>
          <button type='button' className='btn btn-primary' onClick={() => updateStory(story)}>
            <FontAwesomeIcon icon={faPencil} />
          </button>
          <button type='button' className='btn btn-danger' onClick={() => deleteStory(story)}>
            <FontAwesomeIcon icon={faTrash} />
          </button>
        </div>
      </div>
    </div>
  );
};

export default StoryCard;
