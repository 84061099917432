import Modal from 'react-modal';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faClose } from '@fortawesome/free-solid-svg-icons';
import { useEffect } from 'react';
import { Wizard } from 'react-use-wizard';
import { useStoryFormStore } from '../../../utils/stores';
import { useTranslation } from 'react-i18next';
import StoryWizardStep1 from '../stories/formSteps/StoryWizardStep1';
import StoryWizardStep2 from './formSteps/StoryWizardStep2';
import { Story } from '../quests/Quests';

export interface FormStoryPart1 {
  title: string;
  setting: string;
  epilogue: string;
}

export interface FormStoryPart2 {
  hasDifferentiation: boolean;
  differentiationSetting?: string;
}

export interface FormStory extends FormStoryPart1, FormStoryPart2 {
  masterStoryId: number;
}

interface StoryModalProps {
  isOpen: boolean; // State of the modal
  onClose: () => void; // Closing function
  editStory?: Story | null; // Defines if we edit or create a Story
  masterStoryId: number; // Parent MasterStory ID
}

const StoryModal = ({ isOpen, onClose, editStory, masterStoryId }: StoryModalProps) => {
  const { t } = useTranslation('global');

  const { updateId, updatePart1, updatePart2 } = useStoryFormStore(); // Used to save the inputs of each of the steps

  useEffect(() => {
    // Update the ID if it exists
    updateId(editStory?.id ?? null);

    // Update Part 1 of the modal, if it exists
    updatePart1(
      editStory
        ? {
            title: editStory.title,
            setting: editStory.setting,
            epilogue: editStory.epilogue,
          }
        : null,
    );

    // Update Part 2 of the modal, if it exists
    updatePart2(
      editStory
        ? {
            ...(editStory.hasDifferentiation
              ? {
                  hasDifferentiation: editStory.hasDifferentiation,
                  differentiationSetting: editStory.differentiationSetting,
                }
              : {
                  hasDifferentiation: editStory.hasDifferentiation,
                  differentiationSetting: '',
                }),
          }
        : null,
    );
  }, [editStory]);

  // Closing the modal
  const closeModal = (): void => {
    onClose();
  };

  // The modal for a substory, a master story contains several different substories
  return (
    <Modal isOpen={isOpen} onRequestClose={closeModal} contentLabel='Story Modal'>
      <div className='w-100 h-100' data-testid='storyModal'>
        <div className='d-flex w-100 justify-content-between'>
          <div>
            <h2>{editStory ? t('story.edit') : t('story.create')}</h2>
          </div>
          <button onClick={closeModal} className='btn btn-primary' data-testid='closeModal'>
            <FontAwesomeIcon icon={faClose} />
          </button>
        </div>

        <Wizard>
          <StoryWizardStep1 />
          <StoryWizardStep2 closeModal={closeModal} masterStoryId={masterStoryId} />
        </Wizard>
      </div>
    </Modal>
  );
};

Modal.setAppElement('#root');

export default StoryModal;
