import Modal from 'react-modal';
import { Concept, QuestStep, Story } from '../Quests';
import { useTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faClose } from '@fortawesome/free-solid-svg-icons';
import { Controller, FieldValues, useForm } from 'react-hook-form';
import {
  CreatedResponse,
  DeletedResponse,
  fetchAPI,
  UpdatedResponse,
} from '../../../../utils/httpRequests';
import { useEffect, useState } from 'react';
import Select, { components, GroupBase, MenuListProps } from 'react-select';
import { useQuery } from 'react-query';
import Loading from '../../../../components/Loading';
import { max50Caracters } from '../../../../utils/utils';
import { JSX } from 'react/jsx-runtime';
import ConceptModal from '../../concepts/ConceptModal';

interface CreateQuestStepModalProps {
  isOpen: boolean;
  onClose: () => void;
  questId: number;
  relatedStory?: Story | null;
  editQuestStep?: QuestStep | null;
  positionX: number;
  positionY: number;
}

interface CreateQuestStepForm extends FieldValues {
  concept: { value: number; label: string };
  dueDate: string;
  rewardXp: number;
  rewardGold: number;
}

interface CreateQuestStepSchema {
  questId: number;
  positionX: number;
  positionY: number;
  dueDate: string;
  rewardXp: number;
  rewardGold: number;
  conceptId: number;
}

const QuestStepModal = ({
  isOpen,
  onClose,
  questId,
  editQuestStep,
  positionX,
  positionY,
  relatedStory,
}: CreateQuestStepModalProps) => {
  const { t } = useTranslation('global');
  const [openConceptModal, setOpenConceptModal] = useState<boolean>(false);

  const closeModal = (): void => {
    onClose();
  };

  const createQuestStep = async (data: CreateQuestStepForm) => {
    const body: CreateQuestStepSchema = {
      ...data,
      conceptId: data.concept.value,
      questId,
      positionX,
      positionY,
    };

    const response = await fetchAPI<CreatedResponse>('/quests/steps', {
      method: 'POST',
      body,
    });

    if (response.isSuccess && response.data.created) {
      closeModal();
    }
  };

  const updateQuestStep = async (data: CreateQuestStepForm) => {
    const body: CreateQuestStepSchema = {
      ...data,
      conceptId: data.concept.value,
      questId,
      positionX,
      positionY,
    };

    const response = await fetchAPI<UpdatedResponse>(`/quests/steps/${editQuestStep?.id}`, {
      method: 'PUT',
      body,
    });

    if (response.isSuccess && response.data.updated) {
      closeModal();
    }
  };

  // Contains all concepts and their state
  const {
    data: concepts,
    isLoading: isConceptsLoading,
    refetch: refetchConcepts,
  } = useQuery('concepts', async () => {
    const concepts = await fetchAPI<Concept[]>(`/concepts`);
    if (concepts.isSuccess) {
      return concepts.data;
    } else {
      return null;
    }
  });

  // Getting the id and title of all concepts for the select input
  const createSelectValue = (id: number, text: string) => {
    return {
      value: id,
      label: max50Caracters(text),
    };
  };

  const deleteQuestStep = async () => {
    if (!editQuestStep) return;
    const deletedResponse = await fetchAPI<DeletedResponse>(`/quests/steps/${editQuestStep.id}`, {
      method: 'DELETE',
    });
    if (deletedResponse.isSuccess) {
      onClose();
    } else {
      return null;
    }
  };

  const {
    register,
    handleSubmit,
    control,
    formState: { errors },
    reset,
    setValue,
  } = useForm<CreateQuestStepForm>({
    defaultValues: {
      rewardXp: 0,
      rewardGold: 0,
    },
  });

  useEffect(() => {
    // Set values of the form to what was saved
    if (editQuestStep) {
      reset({
        concept: createSelectValue(editQuestStep.concept.id, editQuestStep.concept.title),
        dueDate: editQuestStep.dueDate,
        rewardXp: editQuestStep.rewardXp,
        rewardGold: editQuestStep.rewardGold,
      });
    }
  }, [editQuestStep, reset]);

  const SelectMenuButton = (
    props: JSX.IntrinsicAttributes & MenuListProps<unknown, boolean, GroupBase<unknown>>,
  ) => {
    return (
      <components.MenuList {...props}>
        {props.children}
        <button
          onClick={openNewConceptModal}
          className='fw-bold py-1'
          style={{ paddingLeft: '12px' }}
        >
          {t('createNewConcept')}
        </button>
      </components.MenuList>
    );
  };

  const openNewConceptModal = () => {
    setOpenConceptModal(true);
  };

  const closeNewConceptModal = async (id: number | null) => {
    if (id) {
      const c = await refetchConcepts();
      const conceptTitle = c.data?.find((m) => m.id === id)?.title;
      setValue('concept', { value: id, label: conceptTitle ?? '' });
    }
    setOpenConceptModal(false);
  };

  return (
    <Modal isOpen={isOpen} onRequestClose={closeModal} contentLabel='Quest Step Modal'>
      <div className='w-100 h-100' data-testid='questStepModal'>
        <div className='d-flex w-100 justify-content-between'>
          <div>
            <h2>{t(`quest_steps.${editQuestStep ? 'update' : 'create'}`)}</h2>
          </div>
          <button onClick={closeModal} className='btn btn-primary'>
            <FontAwesomeIcon icon={faClose} />
          </button>
        </div>

        {isConceptsLoading ? (
          <Loading />
        ) : (
          <>
            <form onSubmit={handleSubmit(editQuestStep ? updateQuestStep : createQuestStep)}>
              <div className='mb-3'>
                {/* Display the concept associated with the step and allow to select which one you wish to associate */}
                <label className='form-label'>{t('quest_steps.concept')}</label>
                <div>
                  <Controller
                    name='concept'
                    control={control}
                    rules={{ required: true }}
                    render={({ field }) => (
                      <Select
                        {...field}
                        components={{
                          MenuList: SelectMenuButton,
                        }}
                        className={`${errors.concept ? 'is-invalid' : ''}`}
                        options={concepts?.map((c) => createSelectValue(c.id, c.title))}
                      />
                    )}
                  />
                  {errors.concept && (
                    <span className='invalid-feedback'>{t('quest_steps.concept_required')}</span>
                  )}
                </div>
              </div>
              {/* Display the related story */}
              {relatedStory ? (
                <div className='alert alert-info mb-3'>
                  {t('related_story')} : {relatedStory?.title}
                </div>
              ) : (
                <div className='alert alert-warning mb-3'>{t('no_related_story')}</div>
              )}
              {/* Set the amount of XP gained from completing this step */}
              <div className='mb-3'>
                <label htmlFor='questStepXp' className='form-label'>
                  {t('quest_steps.xp')}
                </label>
                <div className='input-group mb-3'>
                  <input
                    type='text'
                    className={`form-control ${errors.rewardXp ? 'is-invalid' : ''}`}
                    id='questStepXp'
                    {...register('rewardXp', { required: true })}
                  />
                  <span className='input-group-text'>XP</span>
                  <span className='invalid-feedback'>{t('quest_steps.xp_required')}</span>
                </div>
              </div>
              {/* Set the amount of GOLD gained from completing this step */}
              <div className='mb-3'>
                <label htmlFor='questStepGold' className='form-label'>
                  {t('quest_steps.gold')}
                </label>
                <div className='input-group mb-3'>
                  <input
                    type='text'
                    className={`form-control ${errors.rewardGold ? 'is-invalid' : ''}`}
                    id='questStepGold'
                    {...register('rewardGold', { required: true })}
                  />
                  <span className='input-group-text'>{t('gold')}</span>
                  <span className='invalid-feedback'>{t('quest_steps.gold_required')}</span>
                </div>
              </div>
              <div className='d-flex gap-2'>
                <button type='submit' className='btn btn-primary'>
                  {editQuestStep ? t('quest_steps.update') : t('quest_steps.create')}
                </button>
                {editQuestStep && (
                  <button type='button' onClick={deleteQuestStep} className='btn btn-danger'>
                    {t('quest_steps.delete')}
                  </button>
                )}
              </div>
            </form>
          </>
        )}
      </div>
      <ConceptModal isOpen={openConceptModal} onClose={closeNewConceptModal} />
    </Modal>
  );
};

export default QuestStepModal;
