import Modal from 'react-modal';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faClose } from '@fortawesome/free-solid-svg-icons';
import { useTranslation } from 'react-i18next';
import FancyTextArea from '../../../components/FancyTextArea';
import { useForm } from 'react-hook-form';
import { CreatedResponse, fetchAPI, UpdatedResponse } from '../../../utils/httpRequests';
import { useEffect, useState } from 'react';
import { MasterStory, Story } from '../quests/Quests';
import StoryModal from './StoryModal';
import { useQuery } from 'react-query';
import StoryCard from './components/StoryCard';

interface MasterStoryModalProps {
  isOpen: boolean; // State of the modal
  onClose: (masterStoryId: number | null) => void; // Closing function
  editMasterStory?: MasterStory | null; // Defines if we edit or create a MasterStory
}

interface FormMasterStory {
  name: string;
  introduction: string;
  conclusion: string;
}

const MasterStoryModal = ({ isOpen, onClose, editMasterStory }: MasterStoryModalProps) => {
  const { t } = useTranslation('global');
  const [editStory, setEditStory] = useState<Story | null>(null);
  const [masterStoryId, setMasterStoryId] = useState<number | null>(null);
  const [storyModalOpen, setStoryModalOpen] = useState(false);

  const {
    register,
    handleSubmit,
    control,
    reset,
    formState: { errors },
  } = useForm<FormMasterStory>();

  useEffect(() => {
    // Reset the form values after it's closed
    reset({
      name: editMasterStory?.name,
      introduction: editMasterStory?.introduction,
      conclusion: editMasterStory?.conclusion,
    });
    setMasterStoryId(editMasterStory?.id ?? null);
  }, [editMasterStory, reset, onClose]);

  const { data: masterStory, refetch } = useQuery(['masterstory', masterStoryId], () =>
    fetchMasterStory(),
  );

  const closeModal = (): void => {
    onClose(masterStoryId);
  };

  const handleFormSubmit = async (data: FormMasterStory): Promise<void> => {
    if (editMasterStory || masterStoryId) {
      await updateMasterStory(data);
    } else {
      await createMasterStory(data);
    }
  };

  const createMasterStory = async (data: FormMasterStory): Promise<void> => {
    const body = data;

    const response = await fetchAPI<CreatedResponse>('/master-stories', {
      method: 'POST',
      body,
    });

    if (response.isSuccess && response.data.created) {
      setMasterStoryId(response.data.created);
      await refetch();
    }
  };

  const deleteStory = async (story: Story) => {
    const response = await fetchAPI(`/stories/${story.id}`, {
      method: 'DELETE',
    });

    if (response.isSuccess) {
      await refetch();
    }
  };

  const updateMasterStory = async (data: FormMasterStory): Promise<void> => {
    const body = data;

    const response = await fetchAPI<UpdatedResponse>(
      `/master-stories/${editMasterStory?.id || masterStoryId}`,
      {
        method: 'PUT',
        body,
      },
    );

    if (response.isSuccess && response.data.updated) {
      onClose(masterStoryId);
    }
  };

  const fetchMasterStory = async () => {
    if (masterStoryId) {
      const response = await fetchAPI<MasterStory>(`/master-stories/${masterStoryId}`);

      if (response.isSuccess) {
        return response.data;
      }
    }
    return null;
  };

  const closeAfterCreateStory = async () => {
    setEditStory(null);
    setStoryModalOpen(false);
    await refetch();
  };

  const updateStory = (story: Story) => {
    setEditStory(story);
    setStoryModalOpen(true);
  };

  // Modal for a master story
  return (
    <Modal isOpen={isOpen} onRequestClose={closeModal} contentLabel='Master Story Modal'>
      <div className='w-100 h-100' data-testid='masterStoryModal'>
        <div className='d-flex w-100 justify-content-between'>
          <div>
            <h2>{editMasterStory ? t('master_story.edit') : t('master_story.create')}</h2>
          </div>
          <button type='button' onClick={closeModal} className='btn btn-primary'>
            <FontAwesomeIcon icon={faClose} />
          </button>
        </div>
        <form onSubmit={handleSubmit(handleFormSubmit)}>
          <div className='mb-3'>
            <label htmlFor='masterStoryName' className='form-label'>
              {t('name')}
            </label>
            <input
              type='text'
              id='masterStoryName'
              className={`form-control ${errors.name ? 'is-invalid' : ''}`}
              {...register('name', { required: true })}
            />
            {errors.name && <span className='invalid-feedback'>{t('title.required')}</span>}
          </div>

          <div className='mb-3'>
            {/* The intro to the master story of a quest */}
            <label htmlFor='masterStoryIntro' className='form-label'>
              {t('introduction')}
            </label>
            <FancyTextArea<FormMasterStory>
              id='masterStoryIntro'
              defaultValue={''}
              name='introduction'
              required={true}
              control={control}
              className={`${errors.introduction ? 'is-invalid' : ''}`}
            />
            {errors.introduction && (
              <span className='invalid-feedback'>{t('setting.required')}</span>
            )}
          </div>
          <div className='mb-3'>
            {/* The conclusion fo a master story of a quest */}
            <label htmlFor='storyEpilogue' className='form-label'>
              {t('conclusion')}
            </label>
            <FancyTextArea<FormMasterStory>
              id='storyEpilogue'
              defaultValue={''}
              name='conclusion'
              required={true}
              control={control}
              className={`${errors.conclusion ? 'is-invalid' : ''}`}
            />
            {errors.conclusion && (
              <span className='invalid-feedback'>{t('epilogue.required')}</span>
            )}
          </div>

          <button type='submit' className='btn btn-primary mb-3'>
            {editMasterStory || masterStoryId ? t('master_story.edit') : t('master_story.create')}
          </button>
        </form>
        {masterStoryId && (
          <div>
            {masterStory?.stories.length ? (
              <div className='pb-2'>
                {!!masterStory?.stories.length && (
                  <div className='d-flex justify-content-between'>
                    <h2>{t('relatedStories')}</h2>
                    <div>
                      <button
                        type='button'
                        onClick={() => setStoryModalOpen(true)}
                        className='btn btn-primary btn-sm'
                      >
                        {t('story.create')}
                      </button>
                    </div>
                  </div>
                )}
                {/* All the substories of a master story */}
                {masterStory?.stories.map((story) => (
                  <StoryCard
                    key={story.id}
                    story={story}
                    updateStory={updateStory}
                    deleteStory={deleteStory}
                  />
                ))}
              </div>
            ) : (
              <div className='alert alert-success' role='alert'>
                {t('theMasterStoryHasBeenCreatedDoYouWantTo')}{' '}
                <button type='button' onClick={() => setStoryModalOpen(true)} className='btn-link'>
                  {t('createAStoryForIt')}
                </button>
                ?
              </div>
            )}
          </div>
        )}
      </div>
      {masterStoryId && (
        <StoryModal
          isOpen={storyModalOpen}
          onClose={closeAfterCreateStory}
          masterStoryId={masterStoryId}
          editStory={editStory}
        />
      )}
    </Modal>
  );
};

Modal.setAppElement('#root');

export default MasterStoryModal;
