import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

const Home = () => {
  const { t } = useTranslation('global');
  return (
    <div className='container mt-5' data-testid='home'>
      <div className='text-center'>
        <h1>{t('imagineQuestLearning')}</h1>
        <p className='lead'>{t('home.intro')}</p>
      </div>

      <div className='mt-4 d-flex justify-content-center'>
        {/*Ask user if it wants to sign up or sign in*/}
        <div className='row'>
          {/*sign up*/}
          <div className='media-object col-md-6 mb-3'>
            <Link
              to='/auth/sign-up'
              className='d-inline-flex align-items-center btn btn-primary btn-lg px-4 rounded-pill option-button'
              data-testid='signUp'
            >
              {t('register')}
            </Link>
          </div>
          {/*sign in*/}
          <div className='media-object col-md-6 mb-3'>
            <Link
              data-testid='signIn'
              to='/auth/sign-in'
              className='d-inline-flex align-items-center btn btn-secondary btn-lg px-4 rounded-pill option-button'
            >
              {t('login')}
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Home;
