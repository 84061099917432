import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

const AccountType = () => {
  const { t } = useTranslation('global');

  return (
    <div className='container my-5' data-testid='accountType'>
      {/* User selects if they want to create TEACHER or STUDENT account */}
      <div className='p-5 text-center bg-body-tertiary rounded-3'>
        <h1 className='text-body-emphasis'> {t('selectAccount')} </h1>
        <p className='col-lg-8 mx-auto fs-5 text-muted'> {t('accountTypeMessage')} </p>
        <div className='d-inline-flex gap-2 mb-5'>
          <Link
            to={'/auth/sign-up/student'}
            className='d-inline-flex align-items-center btn btn-primary btn-lg px-4 rounded-pill'
          >
            {t('student')}
          </Link>
          <Link
            to={'/auth/sign-up/teacher'}
            className='d-inline-flex align-items-center btn btn-outline-secondary btn-lg px-4 rounded-pill'
          >
            {t('teacher')}
          </Link>
        </div>
      </div>
    </div>
  );
};

export default AccountType;
