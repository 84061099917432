import { QuestLogStatus } from '../pages/teacher/quests/Quests';
import { useTranslation } from 'react-i18next';

const Status = ({ status }: { status?: QuestLogStatus }) => {
  const { t } = useTranslation('global');
  if (status === 'COMPLETED') {
    return <span className='badge rounded-pill text-bg-success'>{t('statuses.completed')}</span>;
  } else if (
    status === 'IN_PROGRESS' ||
    status === 'FAILED' ||
    status === 'WAITING_ON_VALIDATION'
  ) {
    return <span className='badge rounded-pill text-bg-info'>{t('statuses.inProgress')}</span>;
  }
  return <span className='badge rounded-pill text-bg-danger'>{t('statuses.notStarted')}</span>;
};

export default Status;
