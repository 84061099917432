import { Control, Controller, FieldValues, Path, PathValue } from 'react-hook-form';
import Editor from './Editor';

export interface FancyTextAreaProps<T extends FieldValues> {
  id: string;
  name: Path<T>;
  control: Control<T>;
  defaultValue: PathValue<T, Path<T>>;
  required: boolean;
  className?: string;
}

const FancyTextArea = <T extends FieldValues>({
  id,
  name,
  control,
  defaultValue,
  required,
  className = '',
}: FancyTextAreaProps<T>) => {
  return (
    <Controller
      name={name}
      control={control}
      defaultValue={defaultValue}
      rules={{ required }}
      render={({ field: { value, onChange } }) => (
        <Editor
          className={className}
          id={id}
          data-testid='fancyTextArea'
          value={value ?? ''}
          onChange={onChange}
        />
      )}
    />
  );
};

export default FancyTextArea;
