import { Outlet, useNavigate } from 'react-router-dom';
import { useEffect } from 'react';
import { useAuth } from '../utils/stores';

export const ProtectedRoute = () => {
  const navigate = useNavigate();
  const { loading, signedIn } = useAuth();

  // Redirect the user to the login page if he isn't signed in
  useEffect(() => {
    if (!signedIn && !loading) {
      navigate('/auth/sign-in');
    }
  }, [signedIn, navigate, loading]);

  return loading ? (
    <div
      className='w-100 min-vh-100 d-flex justify-content-center'
      style={{ marginTop: '10%' }}
      data-testid='protectedRoutes'
    >
      <div className='spinner-grow' style={{ width: '3em', height: '3em' }} role='status'>
        <span className='visually-hidden'>Loading...</span>
      </div>
    </div>
  ) : (
    <Outlet />
  );
};
