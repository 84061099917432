import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { ResetPasswordBody, fetchAPI } from '../../../utils/httpRequests';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useState } from 'react';

const ResetPassword = () => {
  const navigate = useNavigate();
  const [showErrorTemp, setShowErrorTemp] = useState(false);
  const [searchParams] = useSearchParams();
  const token = searchParams.get('token');

  const validatePassword = (value: string) => {
    const minLength = 8;
    const hasLowerCase = /[a-z]/.test(value);
    const hasUpperCase = /[A-Z]/.test(value);
    const hasDigit = /\d/.test(value);
    const hasSpecialChar = /[!@#$%^&*()_+{}[\]:;<>,.?~\\/-]/.test(value);

    return value.length >= minLength && hasLowerCase && hasUpperCase && hasDigit && hasSpecialChar;
  };

  // Makes sure Password and Confirm Password match
  const validateConfirmPassword = (value: string) => {
    const passwordValue = watch('password');
    return value === passwordValue || t('confirmPasswordError');
  };

  // Sends request to submit a new password
  const onSubmit = async (body: ResetPasswordBody) => {
    setShowErrorTemp(false);
    const response = await fetchAPI('/auth/reset-password', {
      method: 'POST',
      body: body,
    });

    if (response.isSuccess) {
      navigate('/auth/sign-in', { state: { passwordReset: true } });
    } else {
      setShowErrorTemp(true);
    }
  };

  const {
    register,
    formState: { errors },
    watch,
    handleSubmit,
  } = useForm<ResetPasswordBody>({
    defaultValues: {
      token: token != null ? token : '',
    },
  });
  const { t } = useTranslation('global');

  return (
    <div className='body-signin' data-testid='resetPassword'>
      {/* Form for user to input new password */}
      <form className='form-signin' onSubmit={handleSubmit(onSubmit)}>
        {showErrorTemp && (
          <div className='alert alert-danger d-flex justify-content-between' role='alert'>
            {t('errorResetRequest')}
          </div>
        )}
        <h1 className='h3 mb-3 font-weight-normal text-center'>{t('enterNewPassword')}</h1>
        <div className='mb-3'>
          <label htmlFor='password'>{t('password')}</label>
          <input
            type='password'
            id='password'
            {...register('password', {
              required: t('passwordRequiredError'),
              validate: (value: string) => validatePassword(value),
            })}
            className={`form-control ${errors.password ? 'is-invalid' : ''}`}
          />
          {errors.password && (
            <div className='invalid-feedback'>
              {errors.password.type === 'required'
                ? t('passwordRequiredError')
                : t('passwordFormatError')}
            </div>
          )}
        </div>
        <div className='mb-3'>
          <label htmlFor='confirmPassword'>{t('confirmPassword')}</label>
          <input
            type='password'
            id='confirmPassword'
            {...register('passwordConfirmation', {
              required: t('confirmPassword'),
              validate: (value: string) => validateConfirmPassword(value),
            })}
            className={`form-control ${errors.passwordConfirmation ? 'is-invalid' : ''}`}
          />
          {errors.passwordConfirmation && (
            <div className='invalid-feedback'>
              {errors.passwordConfirmation.type === 'required'
                ? t('confirmPasswordRequiredError')
                : t('confirmPasswordMatchError')}
            </div>
          )}
        </div>
        <button className='btn btn-lg btn-primary btn-block' data-testid='submit'>
          {t('resetPassword')}
        </button>
      </form>
    </div>
  );
};

export default ResetPassword;
