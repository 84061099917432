import 'quill/dist/quill.snow.css';

import QuillResizeImage from 'quill-resize-image';
import { FC, useEffect, useRef } from 'react';
import Quill from 'quill';

const modules = {
  toolbar: [
    [{ header: [1, 2, false] }],
    ['bold', 'italic', 'underline', 'strike', 'blockquote'],
    [{ list: 'ordered' }, { list: 'bullet' }, { indent: '-1' }, { indent: '+1' }],
    ['link', 'image', 'video'],
    ['clean'],
  ],
  resize: {
    locale: {},
  },
};

const formats = [
  'header',
  'bold',
  'italic',
  'underline',
  'strike',
  'blockquote',
  'list',
  'indent',
  'link',
  'image',
  'video',
];

Quill.register('modules/resize', QuillResizeImage, true);

interface QuillEditorProps {
  value: string;
  onChange: (value: string) => void;
  className?: string;
  id: string;
}

const Editor: FC<QuillEditorProps> = ({ value, onChange, className, id }) => {
  const editorRef = useRef<HTMLDivElement>(null);
  const quillInstance = useRef<Quill | null>(null);

  useEffect(() => {
    if (editorRef.current && !quillInstance.current) {
      quillInstance.current = new Quill(editorRef.current, {
        theme: 'snow',
        modules,
        formats,
      });

      quillInstance.current.on(Quill.events.TEXT_CHANGE, () => {
        if (!quillInstance.current) return;
        const html = quillInstance.current.root.innerHTML;
        onChange(html === '<p><br></p>' ? '' : html);
      });
    }
  }, [onChange]);

  useEffect(() => {
    if (quillInstance.current && value !== quillInstance.current.root.innerHTML) {
      quillInstance.current.root.innerHTML = value;
    }
  }, [value]);

  return (
    <div
      ref={editorRef}
      id={id}
      className={`${editorRef.current?.className ?? ''} ${className}`}
      data-testid='editor'
    />
  );
};

export default Editor;
